import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Modal, Box, CircularProgress } from '@mui/material';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || '';

const CloseShiftModal = ({ shiftData, onClose, open }) => {
    const [rentData, setRentData] = useState(null);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        if (shiftData) {
            axios.get(`${apiUrl}/api/v1/rent/?shift_id=${shiftData.id}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            })
                .then(response => {
                    setRentData(response.data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("There was an error fetching the rent data!", error);
                    setLoading(false);
                });
        }
    }, [shiftData]);

    const calculateMetrics = (data) => {
        const totalRentsStarted = data.filter(rent => rent.started_at).length;
        const totalRentsClosed = data.filter(rent => rent.closed_at).length;
        const totalTurnover = data.reduce((sum, rent) => {
            return sum + (rent.closed_at ? rent.payment : rent.prepayment);
        }, 0);
        const cashTurnover = data.reduce((sum, rent) => {
            if ((rent.payment_type_id === 'ef1ceb7e-a360-49c7-8a03-83bc64db3466') ||
                (rent.prepayment_type_id === 'ef1ceb7e-a360-49c7-8a03-83bc64db3466')) {
                console.log(rent.payment_type_id);
                console.log(rent.prepayment_type_id);
                return sum + (rent.closed_at ? rent.payment : rent.prepayment);
            }
            return sum;
        }, 0);

        return {
            totalRentsStarted,
            totalRentsClosed,
            totalTurnover,
            cashTurnover
        };
    };

    const metrics = rentData ? calculateMetrics(rentData) : {};

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '90%',
                    maxWidth: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    overflowY: 'auto', // Enable scrolling for smaller screens
                }}
            >
                {loading ? (
                    <CircularProgress />
                ) : (
                    shiftData && (
                        <>
                            <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                                Данные по закрытию смены
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Дата закрытия смены: {new Date(shiftData.closed_at * 1000).toLocaleString()}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Точка, в которой смена закрылась: {shiftData.rent_point.name}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Имя того, кто закрыл смену: {shiftData.created_by_user.display_name}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Всего аренд запущено: {metrics.totalRentsStarted}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Всего аренд закрыто: {metrics.totalRentsClosed}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Общий оборот за смену: {metrics.totalTurnover}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Из них наличные: {metrics.cashTurnover}
                            </Typography>
                        </>
                    )
                )}
                <Button variant="contained" color="primary" fullWidth onClick={onClose} size="large">
                    Закрыть
                </Button>
            </Box>
        </Modal>
    );
};

CloseShiftModal.propTypes = {
    shiftData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        closed_at: PropTypes.number.isRequired,
        rent_point: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired,
        created_by_user: PropTypes.shape({
            display_name: PropTypes.string.isRequired
        }).isRequired
    }),
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default CloseShiftModal;


import React, { useState, useEffect } from 'react';
import { Modal, Typography, TextField, Button, Select, MenuItem } from '@mui/material';
import axios from 'axios';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const AddObjectIdModal = ({ isOpen, onClose, rentId, onObjectAdded }) => {
    const [inventoryNumber, setInventoryNumber] = useState('');
    const [selectedObject, setSelectedObject] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState('');
    const [error, setError] = useState(null);
    const [prices, setPrices] = useState([]);
    const [isScanning, setIsScanning] = useState(false);

    useEffect(() => {
        if (isOpen) {
            fetchPrices();
        }
    }, [isOpen]);

    const fetchPrices = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/price/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`,
                },
            });
            setPrices(response.data);
        } catch (error) {
            console.error('Error fetching prices:', error);
            setError('Failed to fetch prices. Please try again.');
        }
    };

    const handleObjectSearch = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent_object/`, {
                params: { inventory_number: inventoryNumber },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`,
                },
            });

            if (response.status === 200) {
                const object = response.data[0]; // Assuming response is an array
                setSelectedObject(object);
                setError(null);
            }
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setError('Такого объекта не существует');
            } else {
                console.error('Error fetching rent object details:', error);
                setError('Failed to fetch rent object details. Please try again.');
            }
        }
    };

    const handleAddToRent = async () => {
        try {
            await axios.post(
                `${apiUrl}/api/v1/rent/${rentId}/object/`,
                {
                    rent_object_id: selectedObject.id,
                    price_id: selectedPrice,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`,
                    },
                }
            );
            onObjectAdded();
            handleClose();
        } catch (error) {
            console.error('Error adding object to rent:', error);
            setError('Объект уже в аренде. Проверьте не закрытые аренды');
        }
    };

    const handleClose = () => {
        setInventoryNumber('');
        setSelectedObject(null);
        setSelectedPrice('');
        setError(null);
        setIsScanning(false);
        onClose();
    };

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <div style={{ backgroundColor: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: 400, padding: 16 }}>
                <Typography variant="h5" gutterBottom style={{ fontSize: '1.8em', marginBottom: 16 }}>
                    Добавление объекта аренды
                </Typography>
                {error && <Typography color="error" style={{ fontSize: '1.2em', marginBottom: 16 }}>{error}</Typography>}
                {!selectedObject ? (
                    <>
                        <TextField
                            label="Инвентарный номер"
                            value={inventoryNumber}
                            onChange={(e) => setInventoryNumber(e.target.value)}
                            fullWidth
                            required
                            inputProps={{ maxLength: 3 }}
                            style={{ marginBottom: 16 }}
                        />
                        <Button variant="contained" color="primary" onClick={handleObjectSearch} disabled={!inventoryNumber} style={{ fontSize: '1.2em', marginBottom: 8 }}>
                            Найти объект
                        </Button>
                        <Button variant="contained" onClick={handleClose} style={{ fontSize: '1.2em' }}>Отмена</Button>
                    </>
                ) : (
                    <>
                        <Typography variant="h6" style={{ fontSize: '1.5em', marginBottom: 16 }}>{selectedObject.bike_model?.manufacturer} {selectedObject.bike_model?.model}</Typography>
                        <Typography style={{ fontSize: '1.2em', marginBottom: 8 }}>Инвентарный номер: {selectedObject.inventory_number}</Typography>
                        <Typography style={{ fontSize: '1.2em', marginBottom: 8 }}>Тип объекта аренды: {selectedObject.rent_object_type?.name}</Typography>
                        <Typography style={{ fontSize: '1.2em', marginBottom: 16 }}>Цена:
                            <Select
                                value={selectedPrice}
                                onChange={(e) => setSelectedPrice(e.target.value)}
                                fullWidth
                            >
                                <MenuItem value="">Выберите цену</MenuItem>
                                {prices.map(price => (
                                    <MenuItem key={price.id} value={price.id}>{price.name}</MenuItem>
                                ))}
                            </Select>
                        </Typography>
                        <Button variant="contained" color="primary" onClick={handleAddToRent} disabled={!selectedPrice} style={{ fontSize: '1.2em', marginBottom: 8 }}>
                            Добавить в аренду
                        </Button>
                        <Button variant="contained" onClick={handleClose} style={{ fontSize: '1.2em' }}>Отмена</Button>
                    </>
                )}
            </div>
        </Modal>
    );
};
export default AddObjectIdModal;




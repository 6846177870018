import React, { useState, useEffect } from 'react';
import {
    Button, TextField, Select, Typography, MenuItem, FormControlLabel,
    Checkbox, Box, Snackbar, Alert, FormControl, InputLabel, Slider
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import VerificationComponent from './VerificationComponent';
import AddObjectModal from './AddObjectRent';
import QRScanPage from './QRScanPage';

const getApiUrl = () => process.env.REACT_APP_API_URL || '';

const apiUrl = getApiUrl();

const OpenRentPage = () => {
    const [countryCode, setCountryCode] = useState('+7');
    const [phoneNumberDigits, setPhoneNumberDigits] = useState('');
    const [contractNumber, setContractNumber] = useState('');
    const [clientData, setClientData] = useState(null);
    const [rentId, setRentId] = useState('');
    const [objects, setObjects] = useState([]);
    const [prepayment, setPrepayment] = useState('300'); // Установим предоплату по умолчанию 300
    const [prepaymentType, setPrepaymentType] = useState('');
    const [error, setError] = useState(null);
    const [checked, setIsChecked] = useState(false);
    const [openAddObjectModal, setOpenAddObjectModal] = useState(false);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [applyDiscount, setApplyDiscount] = useState(false);
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [pinCode, setPinCode] = useState('');
    const [isVerificationStarted, setIsVerificationStarted] = useState(false);
    const [isRentOpened, setIsRentOpened] = useState(false);
    const [scannedObject, setScannedObject] = useState('');
    const [isOpeningRent, setIsOpeningRent] = useState(false);
    const [estimatedDays, setEstimatedDays] = useState(0);
    const [estimatedHours, setEstimatedHours] = useState(1);
    const [loading, setLoading] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);

    const navigate = useNavigate();


    useEffect(() => {
        // При загрузке страницы, попытаемся извлечь сохранённый номер телефона из localStorage
        const savedPhoneNumber = localStorage.getItem('phoneNumber');
        if (savedPhoneNumber) {
            // Извлекаем код страны и цифры номера
            const countryCode = '+7' // Получаем первые два символа
            const phoneNumberDigits = savedPhoneNumber.substring(2).trim(); // Получаем остальные символы
            setCountryCode(countryCode);
            setPhoneNumberDigits(phoneNumberDigits);
        }
    }, []);

    useEffect(() => {
        if (rentId) fetchObjects();
    }, [rentId]);

    useEffect(() => {
        fetchPaymentTypes();
    }, []);

    const fetchPaymentTypes = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/payment_type/`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
            });
            setPaymentTypes(response.data);
        } catch (error) {
            handleError('Ошибка при получении типов оплаты');
        }
    };

    const fetchObjectName = async (objectId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent_object/${objectId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
            });
            return response.data.name;
        } catch (error) {
            handleError(`Ошибка при получении названия объекта по ID ${objectId}`);
            return '';
        }
    };

    const fetchObjects = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent/${rentId}/object/`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
            });
            const objectsWithNames = await Promise.all(response.data.map(async (object) => {
                const objectName = await fetchObjectName(object.rent_object_id);
                return { ...object, name: objectName };
            }));
            setObjects(objectsWithNames);
        } catch (error) {
            handleError('Ошибка при получении объектов аренды');
        } finally {
            setLoading(false);
        }
    };

    const handleCheckClient = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/v1/client/phone_number/${countryCode}${phoneNumberDigits}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
            });
            setClientData(response.data);
            if (response.data.active) setContractNumber(response.data.contract);
        } catch (error) {
            handleError('Новый клиент');
            setClientData(null);
            setContractNumber('');
        } finally {
            setIsChecked(true);
            setLoading(false);
        }
    };

    const handleOpenRent = async () => {
        setIsOpeningRent(true);
        try {
            const response = await axios.post(`${apiUrl}/api/v1/rent/`, {
                client_id: clientData.id,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
            });
            setRentId(response.data.id);
        } catch (error) {
            handleError('Ошибка при открытии аренды');
        } finally {
            setIsOpeningRent(false);
        }
    };

    const handleConfirmVerification = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/api/v1/client/${clientData.id}/verification/`, {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
            });
            setVerificationId(response.data.id);
            setIsVerificationStarted(true);
        } catch (error) {
            handleError('Ошибка при отправке кода верификации');
        } finally {
            setLoading(false);
        }
    };

    const handleStartRent = async () => {
        setLoading(true);
        try {
            const confirmResponse = await axios.put(`${apiUrl}/api/v1/client/${clientData.id}/verification/${verificationId}/confirm`, {
                code: pinCode,
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
            });

            if (confirmResponse.status === 200) {
                const rentStartResponse = await axios.post(`${apiUrl}/api/v1/rent/${rentId}/start`, {
                    verification_id: verificationId,
                    prepayment,
                    prepayment_type_id: paymentTypes[sliderValue]?.id,
                    discount: applyDiscount ? discountPercentage : 0,
                    estimated_duration: (estimatedDays * 24 + estimatedHours) * 3600,
                }, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
                });

                if (rentStartResponse.status === 200) {
                    alert('Аренда начата');
                    navigate('/');
                }
            } else {
                setError('Неверный код верификации. Пожалуйста, попробуйте ещё раз.');
            }
        } catch (error) {
            handleError('Ошибка при начале аренды');
        } finally {
            setLoading(false);
        }
    };

    const handleCancelRent = async () => {
        setLoading(true);
        try {
            await axios.post(`${apiUrl}/api/v1/rent/${rentId}/cancel`, {}, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
            });
            window.location.reload();
        } catch (error) {
            handleError('Ошибка при отмене аренды');
        } finally {
            setLoading(false);
        }
    };

    const handleScanQRCode = (data) => {
        if (data) {
            setScannedObject(data.text);
            setOpenAddObjectModal(true);
        }
    };

    const handleCloseAddObjectModal = () => {
        setOpenAddObjectModal(false);
        setScannedObject('');
    };

    const handleRemoveObject = async (objectId) => {
        setLoading(true);
        try {
            await axios.delete(`${apiUrl}/api/v1/rent/${rentId}/object/${objectId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('access_token') || ''}` },
            });
            fetchObjects();
        } catch (error) {
            handleError(`Ошибка при удалении объекта ${objectId} из аренды`);
        } finally {
            setLoading(false);
        }
    };

    const handlePhoneNumberDigitsChange = (e) => {
        const value = e.target.value;
        setPhoneNumberDigits(value);
        localStorage.setItem('phoneNumber', `${countryCode} ${value}`);
    };

    const handleError = (message) => {
        setError(message);
        setTimeout(() => setError(null), 5000);
    };

    const renderRegistrationOrVerification = () => {
        if (!checked) return null;

        if (!clientData) {
            return (
                <VerificationComponent
                    phoneNumber={countryCode + phoneNumberDigits}
                    setPhoneNumberDigits={handlePhoneNumberDigitsChange}
                    contractNumber={contractNumber}
                    setContractNumber={setContractNumber}
                    setClientData={setClientData}
                />
            );
        }

        return (
            <>
                <TextField
                    label="Номер договора"
                    value={contractNumber}
                    onChange={(e) => setContractNumber(e.target.value)}
                    fullWidth
                    disabled={clientData.verified}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenRent}
                    disabled={isOpeningRent || !!rentId}
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                >
                    Открыть аренду
                </Button>
            </>
        );
    };

    return (
        <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
            <Box display="flex" alignItems="center" marginBottom="20px">
                <Typography variant="body1" style={{ marginRight: '8px' }}>
                    {countryCode}
                </Typography>
                <TextField
                    value={phoneNumberDigits}
                    onChange={handlePhoneNumberDigitsChange}
                    fullWidth
                    required
                    inputProps={{ maxLength: 10 }}
                    style={{ flex: 1 }}
                />
            </Box>

            {!checked && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCheckClient}
                    disabled={loading}
                    style={{ marginBottom: '20px' }}
                >
                    Отправить
                </Button>
            )}
            {renderRegistrationOrVerification()}
            {rentId && (
                <>
                    <Typography variant="h5" gutterBottom>
                        Список объектов аренды
                    </Typography>
                    {objects.map((object) => (
                        <Box key={object.id} marginBottom="16px">
                            <Typography>
                                {object.rent_object.bike_model.manufacturer} {object.rent_object.bike_model.model} {object.rent_object.inventory_number}
                                <Button onClick={() => handleRemoveObject(object.id)} disabled={loading}>
                                    Удалить объект
                                </Button>
                            </Typography>
                        </Box>
                    ))}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleScanQRCode}
                        disabled={loading}
                        style={{ marginTop: '20px', marginBottom: '20px' }}
                    >
                        Сканировать QR-код для добавления объекта
                    </Button>
                    <AddObjectModal isOpen={openAddObjectModal} onClose={handleCloseAddObjectModal} rentId={rentId} onObjectAdded={fetchObjects} scannedObject={scannedObject} />
                    <TextField
                        label="Предоплата"
                        value={prepayment}
                        onChange={(e) => setPrepayment(e.target.value)}
                        fullWidth
                        required
                        error={!prepayment && !isVerificationStarted}
                        helperText={!prepayment && !isVerificationStarted ? "Поле предоплата обязательно" : ""}
                        style={{ marginBottom: '20px' }}
                    />
                    <InputLabel>Выберите тип оплаты</InputLabel>
                    <FormControl fullWidth required error={!selectedPaymentType && !isVerificationStarted} style={{ marginBottom: '20px' }}>
                        <Slider
                            value={sliderValue}
                            onChange={(event, newValue) => setSliderValue(newValue)}
                            min={0}
                            max={paymentTypes.length - 1}
                            step={1}
                            valueLabelDisplay="auto"
                            aria-labelledby="payment-type-slider"
                        />
                        <Typography variant="subtitle1">
                            {paymentTypes[sliderValue]?.name}
                        </Typography>
                    </FormControl>
                    <div style={{ marginBottom: '20px' }}>
                        <FormControlLabel
                            control={<Checkbox checked={applyDiscount} onChange={(e) => setApplyDiscount(e.target.checked)} />}
                            label={<Typography>Применить скидку</Typography>}
                        />
                    </div>
                    {applyDiscount && (
                        <div style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Скидка"
                                value={discountPercentage}
                                onChange={(e) => setDiscountPercentage(e.target.value)}
                                fullWidth
                            />
                        </div>
                    )}
                    <Typography variant="h6" gutterBottom>
                        Планируемое время аренды
                    </Typography>
                    <Box display="flex" justifyContent="space-between">
                        <div>
                            <Typography variant="subtitle1">Дни</Typography>
                            <Select
                                value={estimatedDays}
                                onChange={(e) => setEstimatedDays(e.target.value)}
                                fullWidth
                            >
                                {[...Array(8).keys()].map((day) => (
                                    <MenuItem key={day} value={day}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <Typography variant="subtitle1">Часы</Typography>
                            <Select
                                value={estimatedHours}
                                onChange={(e) => setEstimatedHours(e.target.value)}
                                fullWidth
                            >
                                {[...Array(8).keys()].map((hour) => (
                                    <MenuItem key={hour + 1} value={hour + 1}>
                                        {hour + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmVerification}
                        disabled={loading}
                        style={{ marginBottom: '20px' }}
                    >
                        Запросить подтверждение
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCancelRent}
                        disabled={loading}
                        style={{ marginBottom: '20px' }}
                    >
                        Отменить аренду
                    </Button>
                </>
            )}
            {isVerificationStarted && (
                <div style={{ marginBottom: '20px' }}>
                    <TextField
                        label="Введите код PIN"
                        value={pinCode}
                        onChange={(e) => setPinCode(e.target.value)}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleStartRent}
                        disabled={loading}
                        style={{ marginBottom: '10px', marginRight: '10px' }}
                    >
                        Начать аренду
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsVerificationStarted(false)}
                        disabled={loading}
                        style={{ marginBottom: '10px' }}
                    >
                        Отмена
                    </Button>
                </div>
            )}

            {error && (
                <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
                    <Alert onClose={() => setError(null)} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
};

export default OpenRentPage;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Modal,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Stack,
} from '@mui/material';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();


const AddtypeModelPage = () => {
    const [type, settype] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [newtypeData, setNewtypeData] = useState({
        name: '',

    });
    const [selectedtypeId, setSelectedtypeId] = useState(null);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetchtype = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/rent_object_type/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    },
                });
                settype(response.data);
            } catch (error) {
                console.error('Error fetching type:', error);
            }
        };

        fetchtype();
    }, [token]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewtypeData({
            name: '',

        });
    };

    const handleOpenEditModal = async (type_model_id) => {
        setSelectedtypeId(type_model_id);
        setOpenEditModal(true);

        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent_object_type/${type_model_id}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
            const typeData = response.data;

            setNewtypeData({
                name: typeData.name,

            });
        } catch (error) {
            console.error('Error fetching type data for editing:', error);
        }
    };


    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    const handleCreatetype = async () => {
        try {
            await axios.post(`${apiUrl}/api/v1/rent_object_type/`, newtypeData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseModal();
        } catch (error) {
            console.error('Error creating type:', error);
        }
    };

    const handleEdittype = async () => {
        try {
            await axios.patch(`${apiUrl}/api/v1/rent_object_type/${selectedtypeId}`, newtypeData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseEditModal();
            window.location.reload();
        } catch (error) {
            console.error('Error editing type:', error);
        }
    };


    const handleChange = (field, value) => {
        setNewtypeData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };



    return (
        <div>
            <h2>Список видов объектов аренды</h2>
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ mb: 2 }}>
                Новый вид объектов аренды
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Вид объекта аренды</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {type.map((type) => (
                            <TableRow key={type.id}>
                                <TableCell>{type.name}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" color="primary" onClick={() => handleOpenEditModal(type.id)}>
                                        Изменить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Регистрация нового типа
                    </Typography>
                    <TextField
                        label="Тип объекта аренды"
                        fullWidth
                        margin="normal"
                        value={newtypeData.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleCreatetype}>
                            Создать
                        </Button>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Редактирование типа
                    </Typography>
                    <TextField
                        label="Тип объекта аренды"
                        fullWidth
                        margin="normal"
                        value={newtypeData.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />

                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleEdittype}>
                            Сохранить
                        </Button>
                        <Button variant="contained" onClick={handleCloseEditModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default AddtypeModelPage;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Typography } from '@mui/material';
import QRScanPage from './QRScanPage';
import axios from 'axios';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const AddObjectModal = ({ isOpen, onClose, rentId, onObjectAdded }) => {
    const [selectedObject, setSelectedObject] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState('');
    const [error, setError] = useState(null);
    const [prices, setPrices] = useState([]);
    const [isScanning, setIsScanning] = useState(true);

    useEffect(() => {
        if (isOpen) {
            fetchPrices();
        }
    }, [isOpen]);

    const fetchPrices = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/price/`, {
                headers: {
                    Authorization: localStorage.getItem('access_token') ? `Bearer ${localStorage.getItem('access_token')}` : '',
                },
            });
            setPrices(response.data);
        } catch (error) {
            console.error('Error fetching prices:', error);
            setError('Failed to fetch prices. Please try again.');
        }
    };

    const handleObjectAdded = async (qrData) => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent_object/by_qr/${qrData}`, {
                headers: {
                    Authorization: localStorage.getItem('access_token') ? `Bearer ${localStorage.getItem('access_token')}` : '',
                },
            });

            if (response.status === 200) {
                setSelectedObject(response.data);
                setIsScanning(false);
                setError(null);
            }
        } catch (error) {
            if (error.response && error.response.status === 422) {
                setError('Такого объекта не существует');
            } else {
                console.error('Error fetching rent object details:', error);
                setError('Объект в аренде. Проверьте не закрытые аренды');
            }
        }
    };

    const handleAddToRent = async () => {
        try {
            await axios.post(
                `${apiUrl}/api/v1/rent/${rentId}/object/`,
                {
                    rent_object_id: selectedObject.id,
                    price_id: selectedPrice,
                },
                {
                    headers: {
                        Authorization: localStorage.getItem('access_token') ? `Bearer ${localStorage.getItem('access_token')}` : '',
                    },
                }
            );
            onObjectAdded();
            setSelectedObject(null);
            setIsScanning(true);
            onClose();
        } catch (error) {
            console.error('Error adding object to rent:', error);
            setError('Failed to add object to rent. Please try again.');
        }
    };

    const handleClose = () => {
        setSelectedObject(null);
        setSelectedPrice('');
        setError(null);
        setIsScanning(true);
        onClose();
    };

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <div style={{ backgroundColor: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '90%', maxWidth: 400, padding: 16 }}>
                <Typography variant="h5" gutterBottom style={{ fontSize: '1.8em', marginBottom: 16 }}>
                    Добавление объекта аренды
                </Typography>
                {error && <Typography color="error" style={{ fontSize: '1.2em', marginBottom: 16 }}>{error}</Typography>}
                {isScanning && (
                    <QRScanPage onScan={handleObjectAdded} />
                )}
                {selectedObject && (
                    <>
                        <Typography variant="h6" style={{ fontSize: '1.5em', marginBottom: 16 }}>{selectedObject.name}</Typography>
                        <Typography style={{ fontSize: '1.2em', marginBottom: 8 }}>Инвентарный номер: {selectedObject.inventory_number}</Typography>
                        <Typography style={{ fontSize: '1.2em', marginBottom: 8 }}>Тип объекта аренды: {selectedObject.rent_object_type.name}</Typography>
                        <Typography style={{ fontSize: '1.2em', marginBottom: 16 }}>Цена:
                            <select value={selectedPrice} onChange={(e) => setSelectedPrice(e.target.value)}>
                                <option value="">Выберите цену</option>
                                {prices.map(price => (
                                    <option key={price.id} value={price.id}>{price.name}</option>
                                ))}
                            </select>
                        </Typography>
                        <Button variant="contained" color="primary" onClick={handleAddToRent} style={{ fontSize: '1.2em', marginBottom: 8 }}>Добавить в аренду</Button>
                    </>
                )}
                <Button variant="contained" onClick={handleClose} style={{ fontSize: '1.2em' }}>Отмена</Button>
            </div>
        </Modal>
    );
};

export default AddObjectModal;





// MenuPage.jsx
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, ButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';

const MenuPage = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [isSuperuser, setIsSuperuser] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        setLoggedIn(token !== null);

        // Получение данных пользователя для определения, является ли он суперпользователем
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/v1/user/me`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });

            if (response.ok) {
                const userData = await response.json();
                setIsSuperuser(userData.superuser);
            } else if (response.status === 401) {
                console.error('Unauthorized');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        window.location.reload();
    };

    return (
        <Grid container justifyContent="center" alignItems="center" height="65vh" style={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <ButtonGroup
                    orientation="vertical"
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{ width: '100%', gap: '8px' }}
                >
                    {loggedIn && (
                        <>
                            <NavLink to="/open-shift">
                                <Button fullWidth sx={{ fontSize: '1.6rem' }}>Открытие смены</Button>
                            </NavLink>
                            <NavLink to="/rent-management">
                                <Button fullWidth sx={{ fontSize: '1.6rem' }}>Аренда</Button>
                            </NavLink>
                            <NavLink to="/points">
                                <Button fullWidth sx={{ fontSize: '1.6rem' }}>Выбор точек проката</Button>
                            </NavLink>
                            <NavLink to="/price">
                                <Button fullWidth sx={{ fontSize: '1.6rem' }}>Тарифы</Button>
                            </NavLink>

                            {!isSuperuser && (
                                <>
                                <NavLink to="/activerent">
                                    <Button fullWidth sx={{ fontSize: '1.6rem' }}>Список активных аренд</Button>
                                </NavLink>
                                <NavLink to="/rentobjects">
                                    <Button fullWidth sx={{ fontSize: '1.6rem' }}>Каталог</Button>
                                </NavLink>
                                </>
                        )}

                            {isSuperuser && (
                                <>
                                    <NavLink to="/contracts">
                                        <Button fullWidth sx={{ fontSize: '1.6rem' }}>Список аренд</Button>
                                    </NavLink>
                                    <NavLink to="/users">
                                        <Button fullWidth sx={{ fontSize: '1.6rem' }}>Сотрудники</Button>
                                    </NavLink>
                                    <NavLink to="/bikes">
                                        <Button fullWidth sx={{ fontSize: '1.6rem' }}>Каталог</Button>
                                    </NavLink>
                                    <NavLink to="/settings">
                                        <Button fullWidth sx={{ fontSize: '1.6rem' }}>Настройки</Button>
                                    </NavLink>
                                </>
                            )}
                            <Button onClick={handleLogout} fullWidth sx={{ fontSize: '1.6rem' }}>Выход</Button>
                        </>
                    )}
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};

export default MenuPage;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Modal,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Stack,
} from '@mui/material';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const DefectsPage = () => {
    const [defects, setDefects] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [newDefectData, setNewDefectData] = useState({
        name: '',
        reparation: 0,
        rent_object_type_id: '',
        object_usage_prohibited: false,
    });
    const [selectedDefectId, setSelectedDefectId] = useState(null);
    const [rentObjectTypes, setRentObjectTypes] = useState([]);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/defect_types/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                });
                setDefects(response.data);
            } catch (error) {
                console.error('Error fetching defects:', error);
            }
        };

        fetchData();
    }, [token]);

    useEffect(() => {
        const fetchRentObjectTypes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/rent_object_type/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                });
                setRentObjectTypes(response.data);
            } catch (error) {
                console.error('Error fetching rent object types:', error);
            }
        };

        fetchRentObjectTypes();
    }, [token]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewDefectData({
            name: '',
            reparation: 0,
            rent_object_type_id: '',
            object_usage_prohibited: false,
        });
    };

    const handleOpenEditModal = async (defectId) => {
        setSelectedDefectId(defectId);
        setOpenEditModal(true);

        try {
            const response = await axios.get(`${apiUrl}/api/v1/defect_types/${defectId}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
            const defectData = response.data;

            setNewDefectData({
                name: defectData.name,
                reparation: defectData.reparation,
                rent_object_type_id: defectData.rent_object_type_id,
                object_usage_prohibited: defectData.object_usage_prohibited,
            });
        } catch (error) {
            console.error('Error fetching defect data for editing:', error);
        }
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    const handleCreateDefect = async () => {
        try {
            await axios.post(`${apiUrl}/api/v1/defect_types/`, newDefectData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseModal();
        } catch (error) {
            console.error('Error creating defect:', error);
        }
    };

    const handleEditDefect = async () => {
        try {
            await axios.patch(`${apiUrl}/api/v1/defect_types/${selectedDefectId}`, newDefectData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseEditModal();
            const updatedDefects = defects.map(defect => {
                if (defect.id === selectedDefectId) {
                    return { ...defect, ...newDefectData };
                }
                return defect;
            });
            setDefects(updatedDefects);
        } catch (error) {
            console.error('Error editing defect:', error);
        }
    };

    const handleChange = (field, value) => {
        setNewDefectData(prevData => ({
            ...prevData,
            [field]: value,
        }));
    };

    return (
        <div>
            <h2>Список дефектов</h2>
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ mb: 2 }}>
                Новый дефект
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название</TableCell>
                            <TableCell>Стоимость возмещения</TableCell>
                            <TableCell>Тип объекта аренды</TableCell>
                            <TableCell>Запрет использования</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {defects.map(defect => (
                            <TableRow key={defect.id}>
                                <TableCell>{defect.name}</TableCell>
                                <TableCell>{defect.reparation}</TableCell>
                                <TableCell>{defect.rent_object_type.name}</TableCell>
                                <TableCell>{defect.object_usage_prohibited ? "Да" : "Нет"}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" color="primary" onClick={() => handleOpenEditModal(defect.id)}>
                                        Изменить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Регистрация нового дефекта
                    </Typography>
                    <TextField
                        label="Название дефекта"
                        fullWidth
                        margin="normal"
                        value={newDefectData.name}
                        onChange={e => handleChange('name', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Тип объекта аренды</InputLabel>
                        <Select
                            value={newDefectData.rent_object_type_id}
                            onChange={e => handleChange('rent_object_type_id', e.target.value)}
                        >
                            {rentObjectTypes.map(rentObjectType => (
                                <MenuItem key={rentObjectType.id} value={rentObjectType.id}>
                                    {rentObjectType.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Стоимость возмещения"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={newDefectData.reparation}
                        onChange={e => handleChange('reparation', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Запрет использования</InputLabel>
                        <Select
                            value={newDefectData.object_usage_prohibited}
                            onChange={e => handleChange('object_usage_prohibited', e.target.value)}
                        >
                            <MenuItem value={true}>Да</MenuItem>
                            <MenuItem value={false}>Нет</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleCreateDefect}>
                            Создать
                        </Button>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Редактирование дефекта
                    </Typography>
                    <TextField
                        label="Название дефекта"
                        fullWidth
                        margin="normal"
                        value={newDefectData.name}
                        onChange={e => handleChange('name', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Тип объекта аренды</InputLabel>
                        <Select
                            value={newDefectData.rent_object_type_id}
                            onChange={e => handleChange('rent_object_type_id', e.target.value)}
                        >
                            {rentObjectTypes.map(rentObjectType => (
                                <MenuItem key={rentObjectType.id} value={rentObjectType.id}>
                                    {rentObjectType.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Стоимость возмещения"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={newDefectData.reparation}
                        onChange={e => handleChange('reparation', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Запрет использования</InputLabel>
                        <Select
                            value={newDefectData.object_usage_prohibited}
                            onChange={e => handleChange('object_usage_prohibited', e.target.value)}
                        >
                            <MenuItem value={true}>Да</MenuItem>
                            <MenuItem value={false}>Нет</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleEditDefect}>
                            Сохранить
                        </Button>
                        <Button variant="contained" onClick={handleCloseEditModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default DefectsPage;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Modal,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Stack,
} from '@mui/material';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const AddBikeModelPage = () => {
    const [bike, setbike] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [newbikeData, setNewbikeData] = useState({
        manufacturer: '',
        model: '',
    });
    const [selectedBikeId, setSelectedbikeId] = useState(null);
    const [selectedBikeType, setSelectedBikeType] = useState('');
    const [bikeTypes, setBikeTypes] = useState([]);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetchbike = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/bike_model/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    },
                });
                setbike(response.data);
            } catch (error) {
                console.error('Error fetching bike:', error);
            }
        };

        fetchbike();
    }, [token]);

    useEffect(() => {
        const fetchBikeTypes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/bike_type/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                });
                setBikeTypes(response.data);
            } catch (error) {
                console.error('Error fetching bike types:', error);
            }
        };

        fetchBikeTypes();
    }, [token]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewbikeData({
            manufacturer: '',
            model: '',
        });
        setSelectedBikeType('');
    };

    const handleOpenEditModal = async (bike_model_id) => {
        setSelectedbikeId(bike_model_id);
        setOpenEditModal(true);

        try {
            const response = await axios.get(`${apiUrl}/api/v1/bike_model/${bike_model_id}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
            const bikeData = response.data;

            setNewbikeData({
                manufacturer: bikeData.manufacturer,
                model: bikeData.model,
            });
            setSelectedBikeType(bikeData.bike_type_id);
        } catch (error) {
            console.error('Error fetching bike data for editing:', error);
        }
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    const handleCreateBike = async () => {
        try {
            await axios.post(`${apiUrl}/api/v1/bike_model/`, { ...newbikeData, bike_type_id: selectedBikeType }, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseModal();
        } catch (error) {
            console.error('Error creating bike:', error);
        }
    };

    const handleEditBike = async () => {
        try {
            await axios.patch(`${apiUrl}/api/v1/bike_model/${selectedBikeId}`, { ...newbikeData, bike_type_id: selectedBikeType }, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseEditModal();
            window.location.reload();
        } catch (error) {
            console.error('Error editing bike:', error);
        }
    };

    const handleChange = (field, value) => {
        setNewbikeData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const getBikeTypeName = (bikeTypeId) => {
        const bikeType = bikeTypes.find(type => type.id === bikeTypeId);
        return bikeType ? bikeType.name : '';
    };

    return (
        <div>
            <h2>Список моделей</h2>
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ mb: 2 }}>
                Новая модель
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Производитель</TableCell>
                            <TableCell>Модель</TableCell>
                            <TableCell>Тип</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bike.map((bike) => (
                            <TableRow key={bike.id}>
                                <TableCell>{bike.manufacturer}</TableCell>
                                <TableCell>{bike.model}</TableCell>
                                <TableCell>{getBikeTypeName(bike.bike_type_id)}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" color="primary" onClick={() => handleOpenEditModal(bike.id)}>
                                        Изменить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Регистрация новой модели
                    </Typography>
                    <TextField
                        label="Производитель"
                        fullWidth
                        margin="normal"
                        value={newbikeData.manufacturer}
                        onChange={(e) => handleChange('manufacturer', e.target.value)}
                    />
                    <TextField
                        label="Марка"
                        fullWidth
                        margin="normal"
                        value={newbikeData.model}
                        onChange={(e) => handleChange('model', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="bike-type-label">Категория</InputLabel>
                        <Select
                            labelId="bike-type-label"
                            id="bike-type-select"
                            value={selectedBikeType}
                            onChange={(e) => setSelectedBikeType(e.target.value)}
                        >
                            {bikeTypes.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleCreateBike}>
                            Создать
                        </Button>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Редактирование модели
                    </Typography>
                    <TextField
                        label="Производитель"
                        fullWidth
                        margin="normal"
                        value={newbikeData.manufacturer}
                        onChange={(e) => handleChange('manufacturer', e.target.value)}
                    />
                    <TextField
                        label="Марка"
                        fullWidth
                        margin="normal"
                        value={newbikeData.model}
                        onChange={(e) => handleChange('model', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="bike-type-label">Категория</InputLabel>
                        <Select
                            labelId="bike-type-label"
                            id="bike-type-select"
                            value={selectedBikeType}
                            onChange={(e) => setSelectedBikeType(e.target.value)}
                        >
                            {bikeTypes.map((type) => (
                                <MenuItem key={type.id} value={type.id}>
                                    {type.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleEditBike}>
                            Сохранить
                        </Button>
                        <Button variant="contained" onClick={handleCloseEditModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default AddBikeModelPage;

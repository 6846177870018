import React, { useState } from 'react';
import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Import useNavigate

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const VerificationComponent = ({ phoneNumber, setPhoneNumber, contractNumber, setContractNumber, setError }) => {
    const [clientData, setClientData] = useState(null);
    const [verificationId, setVerificationId] = useState(null);
    const [pinCode, setPinCode] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('access_token');
    const navigate = useNavigate();  // Initialize useNavigate


    const handleCreateUser = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${apiUrl}/api/v1/client/`,
                {
                    phone_number: phoneNumber,
                    contract: contractNumber,
                },
                {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    },
                }
            );

            setClientData(response.data);
            handleVerificationCode(response.data.id);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error creating user:', error);

        } finally {
            setLoading(false);
        }
    };

    const handleVerificationCode = async (clientId) => {
        try {
            const response = await axios.post(
                `${apiUrl}/api/v1/client/${clientId}/verification/`,
                {},
                {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    },
                }
            );

            setVerificationId(response.data.id);
        } catch (error) {
            console.error('Error sending verification code:', error);
        }
    };

    const handleConfirmVerification = async () => {
        if (!pinCode) {
            setError('PIN-код обязателен.');
            return;
        }

        setLoading(true);
        try {
            await axios.put(
                `${apiUrl}/api/v1/client/${clientData.id}/verification/${verificationId}/confirm`,
                { code: pinCode },
                {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    },
                }
            );

            handleCloseModal();
            navigate('/rent-management');  // Redirect to the open rent page
        } catch (error) {
            console.error('Error confirming verification:', error);

            window.location.reload()
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setPhoneNumber('');
        setContractNumber('');
        setClientData(null);
        setVerificationId(null);
        setPinCode('');
    };

    return (
        <div>
            <TextField label="Номер договора" value={contractNumber} onChange={e => setContractNumber(e.target.value)} fullWidth required />
            <Button variant="contained" color="primary" onClick={handleCreateUser} disabled={loading}>
                Регистрация
            </Button>

            <Modal open={isModalOpen}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        Подтверждение регистрации
                    </Typography>
                    <TextField
                        label="PIN-код"
                        value={pinCode}
                        onChange={(e) => setPinCode(e.target.value)}
                        fullWidth
                        required
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmVerification}
                        disabled={loading}
                        sx={{ mt: 2 }}
                    >
                        Подтвердить
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCloseModal}
                        sx={{ mt: 2 }}
                        disabled={loading}
                    >
                        Отмена
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default VerificationComponent;
// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Button, Container, Grid, ButtonGroup } from '@mui/material';
import './App.css';
import ProtectedRoute from './pages/ProtectedRoute';
import MenuPage from './pages/MenuPage';
import Login from './pages/Login';
import OpenShiftPage from './pages/OpenShiftPage';
import RentPage from './pages/RentPage';
import PointsPage from './pages/PointsPage';
import Bikes from './pages/bikes';
import RentObjects from './pages/RentObjects';
import Users from './pages/Users';
import Price from './pages/Price';
import ContractsPage from './pages/Contracts';
import ActiveContractsPage from './pages/ActiveRentPage';
import SettingsPage from './pages/SettingsPage';
import AddBikeModelPage from './pages/AddBikeModelPage';
import AddRentPointPage from './pages/AddRentPointPage';
import AddRentObjectypesPage from './pages/AddRentObjectypesPage';
import AddBikeTypePage from './pages/AddBikeTypePage';
import AddDefectTypes from './pages/AddDefectTypes';
import OpenIdRentPage from './pages/OpenIdRentPage'
import QRScanPage from './pages/QRScanPage';
import RentManagementPage from './pages/RentManagementPage';
import OpenRentPage from './pages/OpenRentPage';
import CloseRentPage from './pages/CloseRentPage';
import CloseIdRentPage from './pages/CloseIdRentPage';

const App = () => {
    const [isLoadingUser, setLoadingUser] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        const getApiUrl = () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            return apiUrl || '';
        };

        const apiUrl = getApiUrl();

        const fetchCurrentUser = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/v1/user/me`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                });

                if (response.ok) {
                    const userData = await response.json();
                    setUser(userData);
                    setAuthenticated(true);
                } else if (response.status === 401) {
                    setAuthenticated(false);
                }
            } catch (error) {
                console.error('Error fetching current user:', error);
            } finally {
                setLoadingUser(false);
            }
        };

        fetchCurrentUser();
    }, []);


    return (
        <Router>
            <AppBar position="static">
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <NavLink to="/">
                        <Button color="inherit">Меню</Button>
                    </NavLink>
                    <CurrentUser />
                </Toolbar>
            </AppBar>

            <Container>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={
                        <ProtectedRoute
                            user={user}
                            isLoadingUser={isLoadingUser}
                            isAuthenticated={authenticated}
                        />
                    }>
                        <Route path="/" element={<MenuPage />} />
                        <Route path="/open-shift" element={<OpenShiftPage />} />
                        <Route path="/rent" element={<RentPage />} />
                        <Route path="/points" element={<PointsPage />} />
                        <Route path="/bikes" element={<Bikes />} />
                        <Route path="/rentobjects" element={<RentObjects />} />
                        <Route path="/users" element={<Users />} />
                        <Route path="/price" element={<Price />} />
                        <Route path="/contracts" element={<ContractsPage />} />
                        <Route path="/activerent" element={<ActiveContractsPage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/settings/add-bike-model" element={<AddBikeModelPage />} />
                        <Route path="/settings/add-rent-point" element={<AddRentPointPage />} />
                        <Route path="/settings/add-type" element={<AddRentObjectypesPage />} />
                        <Route path="/settings/add-type-bike" element={<AddBikeTypePage />} />
                        <Route path="/settings/add-type-defect" element={<AddDefectTypes />} />
                        <Route path="/qr-scan" element={<QRScanPage />} />
                        <Route path="/rent-management" element={<RentManagementPage />} />
                        <Route path="/open-rent" element={<OpenRentPage />} />
                        <Route path="/open-rentid" element={<OpenIdRentPage />} />
                        <Route path="/close-rent" element={<CloseRentPage />} />
                        <Route path="/close-rent/:rentId" element={<CloseIdRentPage />} />
                    </Route>
                </Routes>
            </Container>
        </Router>
    );
};

const CurrentUser = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            fetchCurrentUser();
        } else {
            setLoading(false);
        }
    }, []);

    const fetchCurrentUser = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const response = await fetch(`${apiUrl}/api/v1/user/me`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            if (response.ok) {
                const userData = await response.json();
                setCurrentUser(userData);
            } else if (response.status === 401) {
                console.error('Unauthorized');
            }
        } catch (error) {
            console.error('Error fetching current user:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return null;

    return (
        <div>
            {currentUser && (
                <span style={{ marginLeft: 'auto', color: 'white' }}>
                    Привет, {currentUser.display_name}!
                </span>
            )}
        </div>
    );
};

export default App;

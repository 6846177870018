import React, { useState } from 'react';
import { TextField, Button, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(null);
    const navigate = useNavigate();

    const bodyFormData = new FormData();
    bodyFormData.append('username', username);
    bodyFormData.append('password', password);
    bodyFormData.append('grant_type', 'password');
    bodyFormData.append('scope', '');
    bodyFormData.append('client_id', '');
    bodyFormData.append('client_secret', '');

    const getApiUrl = () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        return apiUrl || '';
    };

    const apiUrl = getApiUrl();


    const handleLogin = async () => {
        try {
            const response = await axios.post(
                `${apiUrl}/api/v1/auth/login`, bodyFormData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );
            const { access_token, token_type } = response.data;



            // Сохраняем токен в локальное хранилище
            localStorage.setItem('access_token', `${access_token}`);

            console.log('Login successful:', response.data);
            setLoginError(null);

            // Переходим на маршрут '/'
            navigate('/');
            window.location.reload();


            // Здесь вы можете перенаправить пользователя на другую страницу или выполнить другие действия после успешной авторизации
        } catch (error) {
            console.error('Login failed:', error.response.data);
        }
    };

    return (
        <Container maxWidth="xs">
            <div>
                <Typography variant="h4" align="center" gutterBottom>
                    Логин
                </Typography>
                <TextField
                    label="Логин"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="Пароль"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleLogin}
                >
                    Login
                </Button>
            </div>
        </Container>
    );
};

export default Login;

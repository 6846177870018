import React, { useState, useEffect } from 'react';
import { Typography, Modal, Box, Container, Button } from '@mui/material';
import axios from 'axios';
import QRScanPage from './QRScanPage';
import CloseShiftModal from './CloseShiftModal';

const apiUrl = process.env.REACT_APP_API_URL || '';

const ShiftForm = () => {
    const [isShiftOpen, setIsShiftOpen] = useState(false);
    const [rentPointName, setRentPointName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rentPointId, setRentPointId] = useState('');
    const [closeShiftData, setCloseShiftData] = useState(null);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetchActiveShift = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/shift/active`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Content-Type': 'application/json',
                    },
                });
                const { id, rent_point_id, closed_at } = response.data;
                setIsShiftOpen(!closed_at);
                setRentPointId(rent_point_id);
                if (rent_point_id) {
                    fetchRentPointName(rent_point_id);
                }
            } catch (error) {
                console.error('Error fetching active shift:', error);
            }
        };

        if (token) {
            fetchActiveShift();
        }
    }, [token]);

    const fetchRentPointName = async (id) => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent_point/${id}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            setRentPointName(response.data.name);
        } catch (error) {
            console.error('Error fetching rent point name:', error);
        }
    };

    const handleScan = (id) => {
        setRentPointId(id);
        setIsModalOpen(false);
        openShift(id);
    };

    const openShift = async (id) => {
        try {
            const response = await axios.post(`${apiUrl}/api/v1/shift/`, { rent_point_id: id }, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            setIsShiftOpen(true);
            console.log('Shift opened successfully:', response.data);
            window.location.reload();
        } catch (error) {
            console.error('Error opening shift:', error);
        }
    };

    const handleOpenShift = () => {
        setIsModalOpen(true);
    };

    const handleCloseShift = async () => {
        try {
            const response = await axios.post(`${apiUrl}/api/v1/shift/close`, {}, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            setIsShiftOpen(false);
            setCloseShiftData(response.data);
        } catch (error) {
            console.error('Error closing shift:', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box mt={3}>
                <Typography variant="h4" align="center" gutterBottom>
                    Смена {isShiftOpen ? 'открыта' : 'закрыта'}
                </Typography>
                {isShiftOpen && (
                    <Typography variant="body1" align="center" gutterBottom>
                        Открыта в пункте проката: {rentPointName}
                    </Typography>
                )}
                <Button
                    variant="contained"
                    color={isShiftOpen ? 'secondary' : 'primary'}
                    onClick={isShiftOpen ? handleCloseShift : handleOpenShift}
                    fullWidth
                >
                    {isShiftOpen ? 'Закрыть смену' : 'Открыть смену'}
                </Button>
            </Box>

            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Сканировать QR -код
                    </Typography>
                    <QRScanPage onScan={handleScan} />
                </Box>
            </Modal>
            <CloseShiftModal
                shiftData={closeShiftData}
                open={!isShiftOpen && !!closeShiftData}
                onClose={() => {
                    setRentPointName('');
                    setCloseShiftData(null); // Clearing close shift data
                }}
            />
        </Container>
    );
};

export default ShiftForm;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
} from '@mui/material';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const TariffsPage = () => {
    const [tariffs, setTariffs] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetchTariffs = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/price/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                });
                setTariffs(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching tariffs:', error);
                setLoading(false);
            }
        };

        fetchTariffs();
    }, [token]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Тарифы
            </Typography>
            {tariffs.length === 0 ? (
                <Typography variant="body1">Нет доступных тарифов.</Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Категории</TableCell>
                                <TableCell>Цена в час (обычная)</TableCell>
                                <TableCell>Цена в час (льготная)</TableCell>
                                <TableCell>Длительность (часы)</TableCell>
                                <TableCell>Цена за сутки</TableCell>
                                <TableCell>Цена за 3 дня</TableCell>
                                <TableCell>Цена за неделю</TableCell>
                                <TableCell>Доплата за возврат в пункт проката</TableCell>
                                <TableCell>Активен</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tariffs.map((tariff) => (
                                <TableRow key={tariff.id}>
                                    <TableCell>{tariff.name}</TableCell>
                                    <TableCell>{tariff.price_per_hour_normal || '—'}</TableCell>
                                    <TableCell>{tariff.price_per_hour_reduced || '—'}</TableCell>
                                    <TableCell>{tariff.normal_price_duration_hours || '—'}</TableCell>
                                    <TableCell>{tariff.price_per_day || '—'}</TableCell>
                                    <TableCell>{tariff.price_per_3_day || '—'}</TableCell>
                                    <TableCell>{tariff.price_per_week || '—'}</TableCell>
                                    <TableCell>{tariff.rent_point_penalty || '—'}</TableCell>
                                    <TableCell>{tariff.active ? 'Да' : 'Нет'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

export default TariffsPage;

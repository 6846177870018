import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Card, CardContent, CircularProgress, Box, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const getApiUrl = () => process.env.REACT_APP_API_URL || '';

const apiUrl = getApiUrl();

const ContractsPage = () => {
    const [contracts, setContracts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        fetchContracts();
    }, []);

    const fetchContracts = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent/`, {
                headers: { Authorization: token ? `Bearer ${token}` : '' }
            });

            const contractsData = response.data.map(contract => ({
                ...contract,
                objectsData: Array.isArray(contract.objectsData) ? contract.objectsData : []
            }));

            setContracts(contractsData);
        } catch (error) {
            console.error('Error fetching contracts:', error);
            setError('Не удалось получить данные о контрактах. Пожалуйста, попробуйте позже.');
        } finally {
            setLoading(false);
        }
    };

    const filterContracts = (status) => {
        return contracts.filter(contract => {
            if (status === 'active') {
                return contract.active;
            } else if (status === 'draft') {
                return !contract.closed_at && !contract.canceled_at && !contract.started_at;
            } else if (status === 'completed') {
                return contract.closed_at;
            } else if (status === 'canceled') {
                return contract.canceled_at;
            } else if (status === 'discount') {
                return contract.discount;
            }
            return false;
        });
    };

    const cancelDraft = async (contractId) => {
        try {
            await axios.patch(`${apiUrl}/api/v1/rent/${contractId}/cancel/`, {}, {
                headers: { Authorization: token ? `Bearer ${token}` : '' }
            });
            setContracts(prevContracts => prevContracts.map(contract =>
                contract.id === contractId ? { ...contract, canceled_at: new Date().toISOString() } : contract
            ));
        } catch (error) {
            console.error('Error cancelling draft:', error);
            setError('Не удалось отменить черновик. Пожалуйста, попробуйте позже.');
        }
    };

    const filteredContracts = useMemo(() => ({
        active: filterContracts('active'),
        draft: filterContracts('draft'),
        completed: filterContracts('completed'),
        canceled: filterContracts('canceled'),
        discount: filterContracts('discount')
    }), [contracts]);

    const renderContractCard = (contract, isDraft = false) => {
        const currentTime = new Date().getTime() / 1000; // Convert to seconds
        const isPastEstimatedTime = contract.estimated_time && contract.estimated_time < currentTime;
        const cardStyle = isPastEstimatedTime ? { backgroundColor: 'green' } : { backgroundColor: 'red' };

        return (
            <Card key={contract.id} style={{ minWidth: 300, margin: 10, ...cardStyle }}>
                <CardContent>
                    <Typography variant="h5">ID контракта: {contract.client?.contract}</Typography>
                    <Typography variant="body2">Телефон/имя арендатора: {contract.client?.phone_number}</Typography>
                    {contract.started_at && <Typography variant="body2">Время начала аренды: {new Date(contract.started_at * 1000).toLocaleString()}</Typography>}
                    <Typography variant="body2">Прогнозная выручка: {contract.calculated_cost}</Typography>
                    <Typography variant="body2">Предоплата: {contract.prepayment}</Typography>
                    {contract.started_at_shift?.rent_point?.location && <Typography variant="body2">Адрес старта аренды: {contract.started_at_shift.rent_point.location}</Typography>}
                    {contract.started_at_shift?.created_by_user?.username && <Typography variant="body2">Сотрудник, выдавший: {contract.started_at_shift.created_by_user.username}</Typography>}
                    {contract.objects?.length > 0 && (
                        <>
                            <Typography variant="body2">Объекты аренды:</Typography>
                            <ul>
                                {contract.objects.map(object => (
                                    <li key={object.id}>
                                        {object.rent_object?.bike_model?.manufacturer} {object.rent_object?.bike_model?.model} {object.rent_object?.inventory_number}
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                    {isDraft && (
                        <Button variant="contained" color="secondary" onClick={() => cancelDraft(contract.id)}>Отменить черновик</Button>
                    )}
                </CardContent>
            </Card>
        );
    };

    const groupBy = (array, key) => {
        return array.reduce((result, currentValue) => {
            const groupKey = key instanceof Function ? key(currentValue) : currentValue[key];
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(currentValue);
            return result;
        }, {});
    };

    const groupedCompletedByDateAndEmployee = useMemo(() => {
        const groupedByDate = groupBy(filteredContracts.completed, contract => new Date(contract.closed_at * 1000).toLocaleDateString());
        const groupedByDateAndEmployee = {};

        Object.keys(groupedByDate).forEach(date => {
            groupedByDateAndEmployee[date] = groupBy(groupedByDate[date], contract => contract.started_at_shift.created_by_user.username);
        });

        return groupedByDateAndEmployee;
    }, [filteredContracts.completed]);

    const getTotalEarningsByEmployee = (contracts) => {
        return contracts.reduce((sum, contract) => sum + contract.calculated_cost, 0);
    };

    const totalCompletedContracts = filteredContracts.completed.length;

    if (loading) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    return (
        <div>
            <h1>Аренды</h1>
            {[
                { status: 'active', title: 'Активные аренды' },
                { status: 'draft', title: 'Черновики' },
                { status: 'canceled', title: 'Отмененные аренды' },
                { status: 'discount', title: 'Запрос скидки' }
            ].map(({ status, title }) => (
                <Accordion key={status}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${status}-contracts-content`} id={`${status}-contracts-header`}>
                        <Typography>{title} ({filteredContracts[status].length})</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexWrap="wrap">
                            {filteredContracts[status].map(contract => renderContractCard(contract, status === 'draft'))}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            ))}

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="completed-contracts-content" id="completed-contracts-header">
                    <Typography>Завершенные аренды ({totalCompletedContracts})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {Object.keys(groupedCompletedByDateAndEmployee).map(date => (
                        <Accordion key={date}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${date}-contracts-content`} id={`${date}-contracts-header`}>
                                <Typography>{date} ({Object.keys(groupedCompletedByDateAndEmployee[date]).reduce((count, employee) => count + groupedCompletedByDateAndEmployee[date][employee].length, 0)})</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {Object.keys(groupedCompletedByDateAndEmployee[date]).map(employee => (
                                    <Accordion key={`${date}-${employee}`}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${date}-${employee}-contracts-content`} id={`${date}-${employee}-contracts-header`}>
                                            <Typography>{employee} - {getTotalEarningsByEmployee(groupedCompletedByDateAndEmployee[date][employee])} рублей ({groupedCompletedByDateAndEmployee[date][employee].length})</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box display="flex" flexWrap="wrap">
                                                {groupedCompletedByDateAndEmployee[date][employee].map(contract => renderContractCard(contract))}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default ContractsPage;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    CircularProgress,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Badge,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QRScanPage from './QRScanPage';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const BikesPage = () => {
    const [bikes, setBikes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedBike, setSelectedBike] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [rentObjectTypes, setRentObjectTypes] = useState([]);
    const [rentPoints, setRentPoints] = useState([]);
    const [bikeModels, setBikeModels] = useState([]);
    const [availableModels, setAvailableModels] = useState([]);
    const [categoryCounts, setCategoryCounts] = useState({});
    const [qrScanDialogOpen, setQRScanDialogOpen] = useState(false);
    const [qrInventoryNumber, setQRInventoryNumber] = useState('');
    const token = localStorage.getItem('access_token');
    const [scannedQR, setScannedQR] = useState('');
    const [availableManufacturers, setAvailableManufacturers] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedFrameSize, setSelectedFrameSize] = useState('');
    const [selectedWheelSize, setSelectedWheelSize] = useState('');
    const [showQRScanner, setShowQRScanner] = useState(false);
    const [originalBikes, setOriginalBikes] = useState([]);
    const [filterClicked, setFilterClicked] = useState(false);
    const [filteredBikes, setFilteredBikes] = useState([]); // State for filtered bikes

    const handleScan = (qrCode) => {
        setSelectedBike({
            ...selectedBike,
            qr_inventory_number: qrCode,
        });
        setShowQRScanner(false);
    };

    const handleQRButtonClick = () => {
        setShowQRScanner(true);
    };

    const fetchBikeModels = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/bike_model/`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });

            const uniqueManufacturers = Array.from(new Set(response.data.map(model => model.manufacturer)));
            setBikeModels(response.data);
            setAvailableManufacturers(uniqueManufacturers);
        } catch (error) {
            console.error('Error fetching bike models:', error);
        }
    };

    useEffect(() => {
        const fetchBikes = async () => {
            try {
                const [bikesResponse, bikeModelsResponse] = await Promise.all([
                    axios.get(`${apiUrl}/api/v1/rent_object/`, {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : '',
                        },
                    }),
                    axios.get(`${apiUrl}/api/v1/bike_model/`, {
                        headers: {
                            Authorization: token ? `Bearer ${token}` : '',
                        },
                    })
                ]);

                const bikesData = bikesResponse.data;
                const bikeModelsData = bikeModelsResponse.data;

                // Update the bikes data with manufacturer information
                const updatedBikesData = bikesData.map(bike => {
                    const bikeModel = bikeModelsData.find(model => model.id === bike.bike_model_id);
                    return {
                        ...bike,
                        manufacturer: bikeModel ? bikeModel.manufacturer : 'Unknown'
                    };
                });

                setBikes(updatedBikesData);
                setOriginalBikes(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching bikes:', error);
                setLoading(false);
            }
        };

        const fetchRentObjectTypes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/rent_object_type/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                });
                setRentObjectTypes(response.data);
            } catch (error) {
                console.error('Error fetching rent object types:', error);
            }
        };

        const fetchRentPoints = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/rent_point/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                });
                setRentPoints(response.data);
            } catch (error) {
                console.error('Error fetching rent points:', error);
            }
        };

        fetchRentObjectTypes();
        fetchRentPoints();
        fetchBikes();
        fetchBikeModels();
    }, [token]);

    const handleEditClick = (bike) => {
        setSelectedBike(bike);
        setEditDialogOpen(true);
    };

    const handleAddClick = () => {
        setAddDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
    };

    const getTypeNameById = (typeId) => {
        const type = rentObjectTypes.find((type) => type.id === typeId);
        return type ? type.name : '';
    };

    const getPointNameById = (pointId) => {
        const point = rentPoints.find((point) => point.id === pointId);
        return point ? point.name : '';
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
    };

    const handleEditSubmit = async () => {
        try {
            const response = await axios.patch(
                `${apiUrl}/api/v1/rent_object/${selectedBike.id}`,
                selectedBike,
                {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Bike edited:', response.data);
            const updatedBikes = bikes.map((bike) =>
                bike.id === selectedBike.id ? response.data : bike
            );
            setBikes(updatedBikes);
            setEditDialogOpen(false);
        } catch (error) {
            console.error('Error editing bike:', error);
        }
    };

    const handleAddSubmit = async () => {
        try {
            const selectedModel = bikeModels.find(
                (model) => model.manufacturer === selectedBike.manufacturer && model.model === selectedBike.model
            );

            const response = await axios.post(
                `${apiUrl}/api/v1/rent_object/`,
                { ...selectedBike, bike_model_id: selectedBike.model },
                {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Bike added:', response.data);
            setBikes([...bikes, response.data]);
            setAddDialogOpen(false);
        } catch (error) {
            console.error('Error adding bike:', error);
        }
    };

    const handleManufacturerChange = (manufacturer) => {
        const filteredModels = bikeModels.filter((model) => model.manufacturer === manufacturer);
        const availableModelsOptions = filteredModels.map((model) => ({
            label: model.model,
            value: model.id
        }));
        setAvailableModels(availableModelsOptions);
    };

    useEffect(() => {
        const counts = {};
        rentObjectTypes.forEach((type) => {
            const typeBikes = bikes.filter((bike) => bike.rent_object_type_id === type.id);
            counts[type.name] = typeBikes.length;
        });
        setCategoryCounts(counts);
    }, [bikes, rentObjectTypes]);

    const getStatusColor = (bike) => {
        if (!bike.active) {
            return 'grey';
        } else if (bike.current_rent_id) {
            return 'red';
        } else if (bike.current_rent_point_id === 'ремонт') {
            return 'yellow';
        } else {
            return 'green';
        }
    };

    const handleQRScanClose = () => {
        setQRScanDialogOpen(false);
    };

    const handleQRScanResult = (result) => {
        setQRInventoryNumber(result);
        setQRScanDialogOpen(false);
    };


    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
    };

    const handleFrameSizeChange = (event) => {
        setSelectedFrameSize(event.target.value);
    };

    const handleWheelSizeChange = (event) => {
        setSelectedWheelSize(event.target.value);
    };

    const handleFilterClick = () => {
        console.log('Filter button clicked');
        console.log('Selected brand:', selectedBrand);
        console.log('Selected frame size:', selectedFrameSize);
        console.log('Selected wheel size:', selectedWheelSize);

        // Perform filtering logic here
        const filteredBikes = bikes.filter((bike) => {
            console.log('Bike manufacturer:', bike.manufacturer); // Add this line

            const brandMatch = !selectedBrand || bike.manufacturer === selectedBrand;
            const frameSizeMatch = !selectedFrameSize || bike.frame_size === selectedFrameSize;
            const wheelSizeMatch = !selectedWheelSize || bike.wheel_size === selectedWheelSize;

            return brandMatch && frameSizeMatch && wheelSizeMatch;
        });

        console.log('Filtered bikes:', filteredBikes);

        // Update state with filtered bikes
        setBikes(filteredBikes);
    };

    const handleResetClick = () => {
        setSelectedBrand('');
        setSelectedFrameSize('');
        setSelectedWheelSize('');
        setBikes(originalBikes);
        window.location.reload();
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Каталог
            </Typography>
            <Button variant="outlined" color="primary" onClick={handleAddClick}>
                Добавить объект аренды
            </Button>
            <Button variant="outlined" color="primary" onClick={handleFilterClick}>
                Фильтровать
            </Button>
            <Button variant="outlined" color="primary" onClick={handleResetClick}>
                Сбросить фильтр
            </Button>
            <TextField
                select
                label="Марка"
                value={selectedBrand}
                onChange={handleBrandChange} // This should be the correct onChange handler
                fullWidth
            >
                {availableManufacturers.map((model) => (
                    <MenuItem key={model} value={model}>
                        {model}
                    </MenuItem>
                ))}
            </TextField>

            <TextField
                select
                label="Размер рамы"
                value={selectedFrameSize}
                onChange={handleFrameSizeChange}
                fullWidth
            >
                {Array.from(new Set(bikes.map(bike => bike.frame_size))).map(frameSize => (
                    <MenuItem key={frameSize} value={frameSize}>
                        {frameSize}
                    </MenuItem>
                ))}
            </TextField>

            {/* Wheel size filter */}
            <TextField
                select
                label="Размер колеса"
                value={selectedWheelSize}
                onChange={handleWheelSizeChange}
                fullWidth
            >
                {Array.from(new Set(bikes.map(bike => bike.wheel_size))).map(wheelSize => (
                    <MenuItem key={wheelSize} value={wheelSize}>
                        {wheelSize}
                    </MenuItem>
                ))}
            </TextField>

            {Object.entries(categoryCounts).map(([category, count]) => {
                // Filter bikes by the current category

                const categoryFilteredBikes = bikes.filter(bike => getTypeNameById(bike.rent_object_type_id) === category);

                return (
                    <Accordion key={category}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{category}</Typography>
                            <Badge badgeContent={count} max={9999} color="primary" style={{ marginLeft: '8px' }} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {categoryFilteredBikes.map((bike) => {
                                    const bikeModel = bikeModels.find(model => model.id === bike.bike_model_id);
                                    const manufacturer = bikeModel ? bikeModel.manufacturer : 'Unknown';
                                    const model = bikeModel ? bikeModel.model : 'Unknown';

                                    return (
                                        <Grid item key={bike.id} xs={12} sm={6} md={4} lg={3}>
                                            <Card style={{ border: `3px solid ${getStatusColor(bike)}` }}>
                                                <CardContent>
                                                    <Typography variant="h6">{`${manufacturer} ${model}`}</Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Инвентарный номер: {bike.inventory_number}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Комментарий: {bike.comment || '—'}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Размер рамы: {bike.frame_size}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        Размер колеса: {bike.wheel_size}
                                                    </Typography>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() => handleEditClick(bike)}
                                                    >
                                                        Редактировать
                                                    </Button>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}


            <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
                <DialogTitle>Редактирование велосипеда</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Инвентарный номер"
                        value={selectedBike?.inventory_number || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                inventory_number: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />
                    <TextField
                        label="Qr Инвентарный номер"
                        value={selectedBike?.qr_inventory_number || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                qr_inventory_number: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />
                    <Button variant="outlined" onClick={handleQRButtonClick}>Распознать QR</Button>
                    {showQRScanner && <QRScanPage onScan={handleScan} />}

                    <TextField
                        label="Комментарий"
                        value={selectedBike?.comment || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                comment: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Тип объекта аренды"
                        select
                        value={selectedBike?.rent_object_type_id || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                rent_object_type_id: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    >
                        {rentObjectTypes.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </TextField>



                    <TextField
                        label="Размер рамы"
                        value={selectedBike?.frame_size || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                frame_size: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Размер колеса"
                        value={selectedBike?.wheel_size || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                wheel_size: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Количество передач"
                        value={selectedBike?.gear_count || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                gear_count: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Номер рамы"
                        value={selectedBike?.frame_number || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                frame_number: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Текущий пункт аренды"
                        select
                        value={selectedBike?.current_rent_point_id || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                current_rent_point_id: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    >
                        {rentPoints.map((point) => (
                            <MenuItem key={point.id} value={point.id}>
                                {point.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        label="Текущая аренда"
                        value={selectedBike?.current_rent_id || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                current_rent_id: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Цена объекта"
                        value={selectedBike?.object_price || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                object_price: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Не возвращен"
                        value={selectedBike?.not_returned || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                not_returned: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Активен"
                        value={selectedBike?.active || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                active: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose}>Отмена</Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        Принять
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add Bike Modal */}
            <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
                <DialogTitle>Добавление объекта в каталог</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Производитель"
                        select
                        value={selectedBike?.manufacturer || ''}
                        onChange={(e) => {
                            const manufacturer = e.target.value;
                            setSelectedBike({ ...selectedBike, manufacturer });
                            handleManufacturerChange(manufacturer);
                        }}
                        fullWidth
                        style={{ marginTop: '8px' }}
                    >
                        {availableManufacturers.map((manufacturer, index) => (
                            <MenuItem key={index} value={manufacturer}>
                                {manufacturer}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        label="Модель"
                        select
                        value={selectedBike?.model || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                model: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    >
                        {availableModels.map((model) => (
                            <MenuItem key={model.value} value={model.value}>
                                {model.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        label="Инвентарный номер"
                        value={selectedBike?.inventory_number || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                inventory_number: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />
                    <TextField
                        label="Qr Инвентарный номер"
                        value={selectedBike?.qr_inventory_number || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                qr_inventory_number: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />
                    <Button variant="outlined" onClick={handleQRButtonClick}>Распознать QR</Button>
                    {showQRScanner && <QRScanPage onScan={handleScan} />}

                    <TextField
                        label="Комментарий"
                        value={selectedBike?.comment || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                comment: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Тип объекта аренды"
                        select
                        value={selectedBike?.rent_object_type_id || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                rent_object_type_id: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    >
                        {rentObjectTypes.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        label="Размер рамы"
                        value={selectedBike?.frame_size || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                frame_size: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Размер колеса"
                        value={selectedBike?.wheel_size || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                wheel_size: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Количество передач"
                        value={selectedBike?.gear_count || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                gear_count: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Номер рамы"
                        value={selectedBike?.frame_number || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                frame_number: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />

                    <TextField
                        label="Текущий пункт аренды"
                        select
                        value={selectedBike?.current_rent_point_id || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                current_rent_point_id: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    >
                        {rentPoints.map((point) => (
                            <MenuItem key={point.id} value={point.id}>
                                {point.name}
                            </MenuItem>
                        ))}
                    </TextField>


                    <TextField
                        label="Цена объекта"
                        value={selectedBike?.object_price || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                object_price: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />


                    <TextField
                        label="Активен"
                        value={selectedBike?.active || ''}
                        onChange={(e) =>
                            setSelectedBike({
                                ...selectedBike,
                                active: e.target.value,
                            })
                        }
                        fullWidth
                        style={{ marginTop: '8px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddDialogClose}>Отмена</Button>
                    <Button onClick={handleAddSubmit} color="primary">
                        Добавить
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={qrScanDialogOpen} onClose={handleQRScanClose}>
                <DialogContent>
                    <Typography>Сканируйте QR код</Typography>
                    <QRScanPage onScan={handleQRScanResult} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleQRScanClose}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default BikesPage;









import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import QRScanPage from './QRScanPage'; // Adjust the path as per your file structure
import axios from 'axios';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const CloseRentPage = () => {
    const [scannedQRData, setScannedQRData] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (scannedQRData === '') {
            startScanning();
        }
    }, [scannedQRData]);

    const startScanning = async () => {
        try {
            const qrData = await QRScanPage.scan(); // Ensure QRScanPage has a static scan method or adjust as needed
            if (qrData) {
                setScannedQRData(qrData);
                handleScan(qrData);
            }
        } catch (error) {
            console.error('Error scanning QR code:', error);
        }
    };

    const handleScan = async (qrData) => {
        if (qrData) {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/rent_object/by_qr/${qrData}`, {
                    headers: {
                        Authorization: localStorage.getItem('access_token') ? `Bearer ${localStorage.getItem('access_token')}` : '',
                    },
                });
                const rentId = response.data.current_rent_id;

                if (rentId) {
                    // Redirect to the page for closing the rent with the retrieved rent ID
                    window.location.href = `/close-rent/${rentId}`;
                } else {
                    setMessage('Велосипед не в аренде. Перенаправление на экран управления арендой...');
                    setTimeout(() => {
                        window.location.href = '/rent-management';
                    }, 3000); // Redirect after 3 seconds
                }
            } catch (error) {
                console.error('Error fetching rent object:', error);
                setError(error);
            }
        }
    };

    useEffect(() => {
        if (error && error.response && error.response.data && error.response.data.detail === "Invalid Qr") {
            // Close the scanning window and redirect to /rent-management if the QR code is invalid
            setScannedQRData('');
            window.location.href = '/rent-management';
        }
    }, [error]);

    return (
        <Container>
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    {/* Render the QR scanner only if scannedQRData is empty */}
                    {scannedQRData === '' ? (
                        <QRScanPage onScan={handleScan} />
                    ) : (
                        <Typography variant="h6">Сканируем QR Code...</Typography>
                    )}
                    {/* Display message if available */}
                    {message && (
                        <Typography variant="h6" color="error">
                            {message}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default CloseRentPage;


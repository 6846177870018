import React, { useState } from 'react';
import QrReader from 'react-qr-scanner';

const QRScanPage = ({ onScan, onError }) => {
    const [delay] = useState(100); // Delay between scan attempts
    const [result, setResult] = useState(''); // State to store scan result

    const handleScan = (data) => {
        if (data) {
            setResult(data.text); // Set scan result state
            onScan(data.text); // Call onScan callback with scan result
        }
    };

    const handleError = (err) => {
        console.error(err); // Log error to console
        onError(err); // Call onError callback with error
    };

    // Styles to reduce the size of the scanning window
    const previewStyle = {
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <QrReader
                delay={delay} // Set scan delay
                onError={handleError} // Error handler
                onScan={handleScan} // Scan handler
                constraints={{
                    audio: false,
                    video: { facingMode: "environment" } // Use rear camera
                }}
                style={previewStyle} // Apply styles to the scanning component
            />
            {result && (
                <p>{result}</p> // Display scan result if available
            )}
        </div>
    );
};

export default QRScanPage;




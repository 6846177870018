// SettingsPage.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Stack } from '@mui/material';

const SettingsPage = () => (
    <div>
        <h2 >Настройки</h2>
        <Stack spacing={2} textAlign="center">
            <ButtonGroup
                orientation="vertical"
                variant="contained"
                color="primary"
                size="large"
                style={{ width: '100%', gap: '8px' }}
            >
                <Link to="/settings/add-bike-model" style={{ width: '100%' }}>
                    <Button fullWidth sx={{ fontSize: '1.6rem' }}>Модели </Button>
                </Link>
                <Link to="/settings/add-rent-point" style={{ width: '100%' }}>
                    <Button fullWidth sx={{ fontSize: '1.6rem' }}>Точки аренды</Button>
                </Link>
                <Link to="/settings/add-type" style={{ width: '100%' }}>
                    <Button fullWidth sx={{ fontSize: '1.6rem' }}>Объекты аренды</Button>
                </Link>
                <Link to="/settings/add-type-bike" style={{ width: '100%' }}>
                    <Button fullWidth sx={{ fontSize: '1.6rem' }}>Категории объектов аренды</Button>
                </Link>
                <Link to="/settings/add-type-defect" style={{ width: '100%' }}>
                    <Button fullWidth sx={{ fontSize: '1.6rem' }}>Дефекты</Button>
                </Link>

            </ButtonGroup>
        </Stack>
    </div>
);

export default SettingsPage;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Modal,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Stack,
} from '@mui/material';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const AddRentPointPage = () => {
    const [rentpoints, setRentpoints] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [newRentPointData, setNewRentPointData] = useState({
        id: '',
        city_id: '',
        location: '',
        name: '',
        default: false,
        repair_shop: false,
        active: false,
    });
    const [selectedRentPointId, setSelectedRentPointId] = useState(null);
    const [cities, setCities] = useState([]);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetchRentPoints = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/rent_point/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                });
                setRentpoints(response.data);
            } catch (error) {
                console.error('Error fetching rent points:', error);
            }
        };

        fetchRentPoints();
    }, [token]);

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/city/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    },
                });
                setCities(response.data);
            } catch (error) {
                console.error('Error fetching cities:', error);
            }
        };

        fetchCities();
    }, [token]);

    const handleOpenModal = () => {
        setOpenModal(true);
        resetNewRentPointData();
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        resetNewRentPointData();
    };

    const handleOpenEditModal = async (rentpointId) => {
        setSelectedRentPointId(rentpointId);
        setOpenEditModal(true);

        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent_point/${rentpointId}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
            const rentPointData = response.data;

            setNewRentPointData({
                id: rentPointData.id,
                name:rentPointData.name,
                city_id: rentPointData.city_id,
                location: rentPointData.location,
                default: rentPointData.default,
                repair_shop: rentPointData.repair_shop,
                active: rentPointData.active,
            });
        } catch (error) {
            console.error('Error fetching rent point data for editing:', error);
        }
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    const handleCreateRentPoint = async () => {
        try {
            await axios.post(`${apiUrl}/api/v1/rent_point/`, newRentPointData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseModal();
            window.location.reload();
        } catch (error) {
            console.error('Error creating rent point:', error);
        }
    };

    const handleEditRentPoint = async () => {
        try {
            await axios.patch(`${apiUrl}/api/v1/rent_point/${selectedRentPointId}`, newRentPointData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseEditModal();
            window.location.reload();
        } catch (error) {
            console.error('Error editing rent point:', error);
        }
    };

    const handleChange = (field, value) => {
        setNewRentPointData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const resetNewRentPointData = () => {
        setNewRentPointData({
            id: '',
            city_id: '',
            location: '',
            name:'',
            default: false,
            repair_shop: false,
            active: false,
        });
    };

    return (
        <div>
            <h2>Список точек</h2>
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ mb: 2 }}>
                Новая точка аренды
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Город</TableCell>
                            <TableCell>Расположение</TableCell>
                            <TableCell>Название</TableCell>
                            <TableCell>Точка по умолчанию</TableCell>
                            <TableCell>Ремонтная мастерская</TableCell>
                            <TableCell>Активна</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rentpoints.map((rentpoint) => (
                            <TableRow key={rentpoint.id}>
                                <TableCell>{rentpoint.id}</TableCell>
                                <TableCell>{rentpoint.city?.name|| null}</TableCell>
                                <TableCell>{rentpoint.location}</TableCell>
                                <TableCell>{rentpoint.name}</TableCell>
                                <TableCell>{rentpoint.default ? 'Да' : 'Нет'}</TableCell>
                                <TableCell>{rentpoint.repair_shop ? 'Да' : 'Нет'}</TableCell>
                                <TableCell>{rentpoint.active ? 'Да' : 'Нет'}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" color="primary" onClick={() => handleOpenEditModal(rentpoint.id)}>
                                        Изменить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Создание новой точки проката
                    </Typography>
                    <TextField
                        label="Город"
                        fullWidth
                        margin="normal"
                        select
                        value={newRentPointData.city_id}
                        onChange={(e) => handleChange('city_id', e.target.value)}
                    >
                        {cities.map((city) => (
                            <MenuItem key={city.id} value={city.id}>
                                {city.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Расположение"
                        fullWidth
                        margin="normal"
                        value={newRentPointData.location}
                        onChange={(e) => handleChange('location', e.target.value)}
                    />
                    <TextField
                        label="Название"
                        fullWidth
                        margin="normal"
                        value={newRentPointData.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Точка по умолчанию</InputLabel>
                        <Select
                            value={newRentPointData.default}
                            onChange={(e) => handleChange('default', e.target.value)}
                        >
                            <MenuItem value={false}>Нет</MenuItem>
                            <MenuItem value={true}>Да</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Ремонтная мастерская</InputLabel>
                        <Select
                            value={newRentPointData.repair_shop}
                            onChange={(e) => handleChange('repair_shop', e.target.value)}
                        >
                            <MenuItem value={false}>Нет</MenuItem>
                            <MenuItem value={true}>Да</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Активна</InputLabel>
                        <Select
                            value={newRentPointData.active}
                            onChange={(e) => handleChange('active', e.target.value)}
                        >
                            <MenuItem value={false}>Нет</MenuItem>
                            <MenuItem value={true}>Да</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleCreateRentPoint}>
                            Создать
                        </Button>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Редактирование точки проката
                    </Typography>
                    <TextField
                        label="Город"
                        fullWidth
                        margin="normal"
                        select
                        value={newRentPointData.city_id}
                        onChange={(e) => handleChange('city_id', e.target.value)}
                    >
                        {cities.map((city) => (
                            <MenuItem key={city.id} value={city.id}>
                                {city.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Расположение"
                        fullWidth
                        margin="normal"
                        value={newRentPointData.location}
                        onChange={(e) => handleChange('location', e.target.value)}
                    />
                    <TextField
                        label="Название"
                        fullWidth
                        margin="normal"
                        value={newRentPointData.location}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Точка по умолчанию</InputLabel>
                        <Select
                            value={newRentPointData.default}
                            onChange={(e) => handleChange('default', e.target.value)}
                        >
                            <MenuItem value={false}>Нет</MenuItem>
                            <MenuItem value={true}>Да</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Ремонтная мастерская</InputLabel>
                        <Select
                            value={newRentPointData.repair_shop}
                            onChange={(e) => handleChange('repair_shop', e.target.value)}
                        >
                            <MenuItem value={false}>Нет</MenuItem>
                            <MenuItem value={true}>Да</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Активна</InputLabel>
                        <Select
                            value={newRentPointData.active}
                            onChange={(e) => handleChange('active', e.target.value)}
                        >
                            <MenuItem value={false}>Нет</MenuItem>
                            <MenuItem value={true}>Да</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleEditRentPoint}>
                            Сохранить
                        </Button>
                        <Button variant="contained" onClick={handleCloseEditModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default AddRentPointPage;

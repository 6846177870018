import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Modal,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Stack,
} from '@mui/material';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [newUserData, setNewUserData] = useState({
        username: '',
        password: '',
        display_name: '',
        phone_number: '',
        superuser: false,
    });
    const [selectedUserId, setSelectedUserId] = useState(null);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersResponse = await axios.get(`${apiUrl}/api/v1/user/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    },
                });
                const usersData = usersResponse.data;

                const shiftsResponse = await axios.get(`${apiUrl}/api/v1/shift/?active=true`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    },
                });
                const activeShifts = shiftsResponse.data;

                // Create a map of rent point names by rent point id
                const rentPointNamesMap = activeShifts.reduce((acc,shift) => {
                    if (shift.rent_point && shift.rent_point.name) {
                        acc[shift.rent_point_id] = shift.rent_point.name;
                    }
                    return acc;
                }, {});

                // Update users data with rent point names
                const updatedUsersData = usersData.map(user => ({
                    ...user,
                    rent_point_name: rentPointNamesMap[user.rent_point_id] || 'Не активно', // Default to 'Не активно' if no rent point name found
                }));

                setUsers(updatedUsersData);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };


        fetchUsers();
    }, [token]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewUserData({
            username: '',
            password: '',
            display_name: '',
            phone_number: '',
            superuser: false,
        });
    };

    const handleOpenEditModal = async (userId) => {
        setSelectedUserId(userId);
        setOpenEditModal(true);

        try {
            const response = await axios.get(`${apiUrl}/api/v1/user/${userId}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
            const userData = response.data;

            setNewUserData({
                username: userData.username,
                password: '',
                display_name: userData.display_name,
                phone_number: userData.phone_number || '',
                superuser: userData.superuser,
            });
        } catch (error) {
            console.error('Error fetching user data for editing:', error);
        }
    };


    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    const handleCreateUser = async () => {
        try {
            await axios.post(`${apiUrl}/api/v1/user/register`, newUserData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseModal();
        } catch (error) {
            console.error('Error creating user:', error);
        }
    };

    const handleEditUser = async () => {
        try {
            await axios.patch(`${apiUrl}/api/v1/user/${selectedUserId}`, newUserData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseEditModal();
            window.location.reload();
        } catch (error) {
            console.error('Error editing user:', error);
        }
    };

    const handleChange = (field, value) => {
        setNewUserData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };



    return (
        <div>
            <h2>Список пользователей</h2>
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ mb: 2 }}>
                Зарегистрировать нового пользователя
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Имя пользователя</TableCell>
                            <TableCell>Активный</TableCell>
                            <TableCell>Суперпользователь</TableCell>
                            <TableCell>Отображаемое имя</TableCell>
                            <TableCell>Номер телефона</TableCell>
                            <TableCell>Дата создания</TableCell>
                            <TableCell>Точка аренды</TableCell>
                            <TableCell>Заработано за смену</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.id}>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.active ? 'Да' : 'Нет'}</TableCell>
                                <TableCell>{user.superuser ? 'Да' : 'Нет'}</TableCell>
                                <TableCell>{user.display_name}</TableCell>
                                <TableCell>{user.phone_number}</TableCell>
                                <TableCell>{new Date(user.created_at * 1000).toLocaleString()}</TableCell>
                                <TableCell>{user.rent_point_name}</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>
                                    <Button variant="outlined" color="primary" onClick={() => handleOpenEditModal(user.id)}>
                                        Изменить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Регистрация нового пользователя
                    </Typography>
                    <TextField
                        label="Имя пользователя"
                        fullWidth
                        margin="normal"
                        value={newUserData.username}
                        onChange={(e) => handleChange('username', e.target.value)}
                    />
                    <TextField
                        label="Пароль"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={newUserData.password}
                        onChange={(e) => handleChange('password', e.target.value)}
                    />
                    <TextField
                        label="Отображаемое имя"
                        fullWidth
                        margin="normal"
                        value={newUserData.display_name}
                        onChange={(e) => handleChange('display_name', e.target.value)}
                    />
                    <TextField
                        label="Номер телефона"
                        fullWidth
                        margin="normal"
                        value={newUserData.phone_number}
                        onChange={(e) => handleChange('phone_number', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Суперпользователь</InputLabel>
                        <Select
                            value={newUserData.superuser}
                            onChange={(e) => handleChange('superuser', e.target.value)}
                        >
                            <MenuItem value={false}>Нет</MenuItem>
                            <MenuItem value={true}>Да</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleCreateUser}>
                            Создать
                        </Button>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Редактирование пользователя
                    </Typography>
                    <TextField
                        label="Имя пользователя"
                        fullWidth
                        margin="normal"
                        value={newUserData.username}
                        onChange={(e) => handleChange('username', e.target.value)}
                    />
                    <TextField
                        label="Пароль"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={newUserData.password}
                        onChange={(e) => handleChange('password', e.target.value)}
                    />
                    <TextField
                        label="Отображаемое имя"
                        fullWidth
                        margin="normal"
                        value={newUserData.display_name}
                        onChange={(e) => handleChange('display_name', e.target.value)}
                    />
                    <TextField
                        label="Номер телефона"
                        fullWidth
                        margin="normal"
                        value={newUserData.phone_number}
                        onChange={(e) => handleChange('phone_number', e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Суперпользователь</InputLabel>
                        <Select
                            value={newUserData.superuser}
                            onChange={(e) => handleChange('superuser', e.target.value)}
                        >
                            <MenuItem value={false}>Нет</MenuItem>
                            <MenuItem value={true}>Да</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleEditUser}>
                            Сохранить
                        </Button>
                        <Button variant="contained" onClick={handleCloseEditModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default UsersPage;

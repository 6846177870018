import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const getApiUrl = () => process.env.REACT_APP_API_URL || '';

const apiUrl = getApiUrl();

const groupByFrameSize = (objects) => {
    return objects.reduce((acc, object) => {
        const frameSize = object.frame_size;
        if (frameSize) {
            if (!acc[frameSize]) acc[frameSize] = [];
            acc[frameSize].push(object);
        }
        return acc;
    }, {});
};

const filterRentObjects = (objects, condition) => objects.filter(condition);

const PointsPage = () => {
    const [points, setPoints] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [rentObjects, setRentObjects] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [rentCount, setRentCount] = useState(0);
    const [availableCount, setAvailableCount] = useState(0);
    const [repairCount, setRepairCount] = useState(0);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        fetchPoints();
    }, []);

    const fetchPoints = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent_point/`, {
                headers: { Authorization: token ? `Bearer ${token}` : '' },
            });
            setPoints(response.data);
        } catch (error) {
            console.error('Error fetching rent points:', error);
        }
    };

    const fetchRentObjects = async (pointId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent_object/`, {
                headers: { Authorization: token ? `Bearer ${token}` : '' },
            });
            setRentObjects(response.data.filter(object => object.current_rent_point_id === pointId));
        } catch (error) {
            console.error('Error fetching rent objects:', error);
        }
    };

    const handlePointClick = async (pointId) => {
        setSelectedPoint(pointId);
        setExpanded(true);
        setRentObjects([]);
        setRentCount(0);
        setAvailableCount(0);
        setRepairCount(0);
        await fetchRentObjects(pointId);
    };

    useEffect(() => {
        if (!rentObjects.length) return;

        setRentCount(filterRentObjects(rentObjects, object => object.current_rent_id).length);
        setAvailableCount(filterRentObjects(rentObjects, object => object.active && !object.current_rent_id).length);
        setRepairCount(filterRentObjects(rentObjects, object => !object.active && !object.current_rent_id).length);
    }, [rentObjects]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <h1>Точки проката</h1>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {points.map((point) => (
                    <Button
                        key={point.id}
                        variant="contained"
                        color="primary"
                        onClick={() => handlePointClick(point.id)}
                        style={{ marginBottom: '10px', textTransform: 'none', width: '200px' }}
                    >
                        {point.city && point.city.name} {point.name}
                    </Button>
                ))}
            </div>
            {selectedPoint && (
                <div>
                    <h2>Список объектов аренды на точке "{points.find((point) => point.id === selectedPoint)?.name}"</h2>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>В аренде ({rentCount})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {Object.entries(groupByFrameSize(filterRentObjects(rentObjects, object => object.current_rent_id))).map(([frameSize, bikes]) => (
                                <Accordion key={frameSize}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{frameSize} ({bikes.length})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul>
                                            {bikes.map(bike => (
                                                <li key={bike.id}>
                                                    {bike.bike_model && bike.bike_model.manufacturer}{' '}
                                                    {bike.bike_model && bike.bike_model.model}{' '}
                                                    {bike.inventory_number}{' '}
                                                    {'('}{bike.wheel_size}{')'}
                                                </li>
                                            ))}
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Доступны ({availableCount})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {Object.entries(groupByFrameSize(filterRentObjects(rentObjects, object => object.active && !object.current_rent_id))).map(([frameSize, bikes]) => (
                                <Accordion key={frameSize}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{frameSize} ({bikes.length})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul>
                                            {bikes.map(bike => (
                                                <li key={bike.id}>
                                                    {bike.bike_model && bike.bike_model.manufacturer}{' '}
                                                    {bike.bike_model && bike.bike_model.model}{' '}
                                                    {bike.inventory_number}{' '}
                                                    {'('}{bike.wheel_size}{')'}
                                                </li>
                                            ))}
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>В ремонте ({repairCount})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {Object.entries(groupByFrameSize(filterRentObjects(rentObjects, object => !object.active && !object.current_rent_id))).map(([frameSize, bikes]) => (
                                <Accordion key={frameSize}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography>{frameSize} ({bikes.length})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ul>
                                            {bikes.map(bike => (
                                                <li key={bike.id}>
                                                    {bike.bike_model && bike.bike_model.manufacturer}{' '}
                                                    {bike.bike_model && bike.bike_model.model}{' '}
                                                    {bike.inventory_number}{' '}
                                                    {'('}{bike.wheel_size}{')'}
                                                </li>
                                            ))}
                                        </ul>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
        </div>
    );
};

export default PointsPage;





import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';


const ProtectedRoute = ({ user, isLoadingUser, isAuthenticated }) => {
    if (isLoadingUser) return null;

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return <Outlet />
};

export default ProtectedRoute;




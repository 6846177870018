import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Container, Grid, Typography, Modal, Select, MenuItem, FormControl, InputLabel, Snackbar } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const ReturnObjectModal = ({ open, onClose, onReturn, rentId, objectId }) => {
    const [defectTypes, setDefectTypes] = useState([]);
    const [selectedDefectType, setSelectedDefectType] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        if (open) {
            fetchDefectTypes();
        }
    }, [open]);

    const fetchDefectTypes = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/defect_types/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setDefectTypes(response.data);
        } catch (error) {
            console.error('Ошибка при получении типов дефектов:', error);
        }
    };

    const handleAddDefect = async () => {
        if (!selectedDefectType) {
            setSnackbarMessage('Выберите тип дефекта');
            setSnackbarOpen(true);
            return;
        }

        try {
            await axios.post(`${apiUrl}/api/v1/rent_object/${objectId}/defects/`, {
                defect_type_id: selectedDefectType,
                rent_id: rentId,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setSnackbarMessage('Дефект успешно добавлен');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Ошибка при добавлении дефекта:', error);
            setSnackbarMessage('Ошибка при добавлении дефекта');
            setSnackbarOpen(true);
        }
    };

    const handleReturnObject = () => {
        onReturn();
        onClose();
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Container
                sx={{
                    backgroundColor: 'white',
                    boxShadow: 24,
                    p: 4,
                    width: '50%',
                    maxWidth: 400,
                    maxHeight: '70%',
                    overflowY: 'auto',
                    borderRadius: 4,
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Сдать объект
                </Typography>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel id="defect-type-label">Тип дефекта</InputLabel>
                    <Select
                        labelId="defect-type-label"
                        id="defect-type"
                        value={selectedDefectType}
                        onChange={(e) => setSelectedDefectType(e.target.value)}
                    >
                        <MenuItem value="">Выберите тип дефекта</MenuItem>
                        {defectTypes.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                {type.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="secondary" onClick={handleAddDefect} sx={{ marginBottom: 2 }}>
                    Добавить дефект
                </Button>
                <Button variant="contained" color="primary" onClick={handleReturnObject}>
                    ОК
                </Button>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                />
            </Container>
        </Modal>
    );
};

const CloseIdRentPage = () => {
    const { rentId } = useParams();
    const navigate = useNavigate();
    const [objects, setObjects] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [prepayment, setPrepayment] = useState(0);
    const [returnedObjectsCount, setReturnedObjectsCount] = useState(0);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedObject, setSelectedObject] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [rentalClosed, setRentalClosed] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        fetchRentObjects();
        fetchPrepayment();
        fetchPaymentTypes();
    }, [rentId]);

    useEffect(() => {
        fetchTotalCost();
    }, [returnedObjectsCount, objects]);

    const fetchRentObjects = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent/${rentId}/object/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setObjects(response.data);
        } catch (error) {
            console.error('Ошибка при получении объектов аренды:', error);
        }
    };

    const fetchTotalCost = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent/${rentId}/cost`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setTotalCost(response.data.total_cost);
        } catch (error) {
            console.error('Ошибка при получении общей стоимости аренды:', error);
        }
    };

    const fetchPrepayment = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent/${rentId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setPrepayment(response.data.prepayment);
        } catch (error) {
            console.error('Ошибка при получении предоплаты:', error);
        }
    };

    const fetchPaymentTypes = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/payment_type/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setPaymentTypes(response.data);
        } catch (error) {
            console.error('Ошибка при получении типов оплаты:', error);
        }
    };

    const handleCloseRent = async () => {
        if (!selectedPaymentType) {
            console.error('Выберите тип оплаты');
            return;
        }

        try {
            await axios.post(`${apiUrl}/api/v1/rent/${rentId}/close`, {
                payment: totalCost - prepayment,
                payment_type_id: selectedPaymentType,
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setRentalClosed(true);
            setSnackbarMessage('Аренда успешно закрыта');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Ошибка при закрытии аренды:', error);
        }
    };

    const handleReturnObject = async () => {
        try {
            await axios.post(`${apiUrl}/api/v1/rent/${rentId}/object/${selectedObject}/return`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                },
            });
            setObjects(objects.filter(object => object.id !== selectedObject));
            setReturnedObjectsCount(returnedObjectsCount + 1);
            setSnackbarMessage('Объект успешно возвращен');
            setSnackbarOpen(true);
            closeModal();
        } catch (error) {
            console.error('Ошибка при возврате объекта:', error);
        }
    };

    const openModal = (objectId) => {
        setSelectedObject(objectId);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleMainMenuClick = () => {
        navigate('/');
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container>
            <ReturnObjectModal
                open={modalOpen}
                onClose={closeModal}
                onReturn={handleReturnObject}
                rentId={rentId}
                objectId={selectedObject}

            />

            <Modal open={rentalClosed} onClose={() => setRentalClosed(false)} sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Container
                    sx={{
                        backgroundColor: 'white',
                        boxShadow: 24,
                        p: 4,
                        width: '50%',
                        maxWidth: 400,
                        maxHeight: '70%',
                        overflowY: 'auto',
                        borderRadius: 4,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Аренда закрыта
                    </Typography>
                    <Button variant="contained" color="primary" onClick={handleMainMenuClick}>
                        ОК
                    </Button>
                </Container>
            </Modal>

            <Typography variant="h4" gutterBottom>
                Объекты аренды
            </Typography>
            <Grid container spacing={2}>
                {objects.map((object) => (
                    <Grid item key={object.id}>
                        <Button variant="outlined" onClick={() => openModal(object.id)}>
                            {object.rent_object.bike_model.manufacturer} {object.rent_object.bike_model.model} {object.rent_object.inventory_number}
                        </Button>
                    </Grid>
                ))}
            </Grid>
            <Typography variant="h6" gutterBottom>
                Общая стоимость: {totalCost}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Полученная предоплата: {prepayment}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Финальный расчет: {totalCost - prepayment}
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="payment-type-label">Тип оплаты</InputLabel>
                <Select
                    labelId="payment-type-label"
                    id="payment-type"
                    value={selectedPaymentType}
                    onChange={(e) => setSelectedPaymentType(e.target.value)}
                    fullWidth
                >
                    <MenuItem value="">Выберите тип оплаты</MenuItem>
                    {paymentTypes.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                            {type.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleCloseRent} sx={{ marginTop: 2 }}>
                Закрыть аренду
            </Button>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Container>
    );
};

export default CloseIdRentPage;



import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography, Box } from '@mui/material';

const RentManagementPage = () => {
    return (
        <Container maxWidth="sm">
            <Typography variant="h4" align="center" gutterBottom>
                Управление арендой
            </Typography>
            <Box sx={{ '& > *': { marginBottom: '16px' } }}>
                <Link to="/open-rent" style={{ textDecoration: 'none', marginBottom: '16px', display: 'block' }}>
                    <Button variant="contained" color="primary" fullWidth sx={{ fontSize: '1.6rem' }}>
                        Открыть аренду
                    </Button>
                </Link>
                <Link to="/open-rentid" style={{ textDecoration: 'none', marginBottom: '16px', display: 'block' }}>
                    <Button variant="contained" color="primary" fullWidth sx={{ fontSize: '1.6rem' }}>
                        Отложенная аренды
                    </Button>
                </Link>
                <Link to="/close-rent" style={{ textDecoration: 'none', display: 'block' }}>
                    <Button variant="contained" color="secondary" fullWidth sx={{ fontSize: '1.6rem' }}>
                        Закрыть аренду
                    </Button>
                </Link>
            </Box>
        </Container>
    );
};

export default RentManagementPage;

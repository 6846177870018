import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Modal,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Stack,
} from '@mui/material';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();


const AddbiketypeModelPage = () => {
    const [bike_type, setbike_type] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [newbike_typeData, setNewbike_typeData] = useState({
        name: '',

    });
    const [selectedbike_typeId, setSelectedbike_typeId] = useState(null);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        const fetchbike_type = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/v1/bike_type/`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    },
                });
                setbike_type(response.data);
            } catch (error) {
                console.error('Error fetching bike_type:', error);
            }
        };

        fetchbike_type();
    }, [token]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setNewbike_typeData({
            name: '',

        });
    };

    const handleOpenEditModal = async (bike_type_model_id) => {
        setSelectedbike_typeId(bike_type_model_id);
        setOpenEditModal(true);

        try {
            const response = await axios.get(`${apiUrl}/api/v1/bike_type/${bike_type_model_id}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
            const bike_typeData = response.data;

            setNewbike_typeData({
                name: bike_typeData.name,

            });
        } catch (error) {
            console.error('Error fetching bike_type data for editing:', error);
        }
    };


    const handleCloseEditModal = () => {
        setOpenEditModal(false);
    };

    const handleCreatebike_type = async () => {
        try {
            await axios.post(`${apiUrl}/api/v1/bike_type/`, newbike_typeData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-bike_type': 'application/json',
                },
            });
            handleCloseModal();
        } catch (error) {
            console.error('Error creating bike_type:', error);
        }
    };

    const handleEditbike_type = async () => {
        try {
            await axios.patch(`${apiUrl}/api/v1/bike_type/${selectedbike_typeId}`, newbike_typeData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                    'Content-Type': 'application/json',
                },
            });
            handleCloseEditModal();
            const updatedBikeTypes = bike_type.map(bikeType => {
                if (bikeType.id === selectedbike_typeId) {
                    return { ...bikeType, name: newbike_typeData.name };
                }
                return bikeType;
            });
            setbike_type(updatedBikeTypes);
        } catch (error) {
            console.error('Error editing bike_type:', error);
        }
    };


    const handleChange = (field, value) => {
        setNewbike_typeData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };



    return (
        <div>
            <h2>Список категорий объектов</h2>
            <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ mb: 2 }}>
                Новая категория
            </Button>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Категории</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bike_type.map((bike_type) => (
                            <TableRow key={bike_type.id}>
                                <TableCell>{bike_type.name}</TableCell>
                                <TableCell>
                                    <Button variant="outlined" color="primary" onClick={() => handleOpenEditModal(bike_type.id)}>
                                        Изменить
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Регистрация нового типа
                    </Typography>
                    <TextField
                        label="Тип объекта аренды"
                        fullWidth
                        margin="normal"
                        value={newbike_typeData.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleCreatebike_type}>
                            Создать
                        </Button>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        Редактирование категории
                    </Typography>
                    <TextField
                        label="Категория объекта аренды"
                        fullWidth
                        margin="normal"
                        value={newbike_typeData.name}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />

                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="primary" onClick={handleEditbike_type}>
                            Сохранить
                        </Button>
                        <Button variant="contained" onClick={handleCloseEditModal}>
                            Отмена
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
};

export default AddbiketypeModelPage;

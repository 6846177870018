import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Card, CardContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const getApiUrl = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    return apiUrl || '';
};

const apiUrl = getApiUrl();

const ActiveContractsPage = () => {
    const [contracts, setContracts] = useState([]);
    const token = localStorage.getItem('access_token');

    useEffect(() => {
        fetchContracts();
    }, []);

    const fetchContracts = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/v1/rent/?active=true`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : ''
                }
            });

            const contractsData = response.data.map(contract => {
                const objectsData = contract.objectsData;
                const processedContract = {
                    ...contract,
                    objectsData: Array.isArray(objectsData) ? objectsData : []
                };
                return processedContract;
            });

            setContracts(contractsData);
        } catch (error) {
            console.error('Error fetching contracts:', error);
        }
    };

    return (
        <div>
            <h1>Аренды</h1>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="active-contracts-content" id="active-contracts-header">
                    <Typography>Активные аренды ({contracts.length})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {contracts.map((contract) => (
                            <Card key={contract.id} style={{ minWidth: 300, margin: 10 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">ID контракта: {contract.client.contract}</Typography>
                                    <Typography variant="body2">Телефон/имя арендатора: {contract.client.phone_number}</Typography>
                                    <Typography variant="body2">Время начала аренды: {new Date(contract.started_at * 1000).toLocaleString()}</Typography>
                                    <Typography variant="body2">Прогнозная выручка: {contract.calculated_cost}</Typography>
                                    <Typography variant="body2">Предоплата: {contract.prepayment}</Typography>
                                    <Typography variant="body2">Адрес старта аренды: {contract.started_at_shift.rent_point.location}</Typography>
                                    <Typography variant="body2">Сотрудник, выдавший: {contract.started_at_shift.created_by_user.username}</Typography>
                                    {contract.objects && contract.objects.length > 0 && (
                                        <div>
                                            <Typography variant="body2">Объекты аренды:</Typography>
                                            <ul>
                                                {contract.objects.map((object) => (
                                                    <li key={object.id}>
                                                        {object.rent_object.bike_model.manufacturer} {object.rent_object.bike_model.model} {object.rent_object.inventory_number}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default ActiveContractsPage;
